<style scoped>
.nameFields {
  font-weight: bold;
  text-align: right;
}

.feedData {
  text-align: left;
}
</style>

<template>
  <div id="app">
    <NavComponent />
    <b-col cols="4">

      <div style="display: flex; flex-direction: row; align-items: center">
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-dropdown
            id="dropdown-1"
            :text="dropdownHideFilterType"
            class="m-md-2"
          >
            <b-dropdown-item @click="changeHideCategoryFilterDropbox('all')"
              >all</b-dropdown-item
            >
            <b-dropdown-item @click="changeHideCategoryFilterDropbox('none')"
              >none</b-dropdown-item
            >
            <b-dropdown-item
              @click="changeHideCategoryFilterDropbox('전체숨김')"
              >전체숨김</b-dropdown-item
            >
            <b-dropdown-item
              @click="changeHideCategoryFilterDropbox('신고누적숨김')"
              >신고누적숨김</b-dropdown-item
            >
            <b-dropdown-item @click="changeHideCategoryFilterDropbox('작외숨')"
              >작외숨</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <b-dropdown id="dropdown-1" :text="dropdownSearchType" class="m-md-2">
          <b-dropdown-item @click="changeDropboxSearchType('email')"
            >email</b-dropdown-item
          >
          <b-dropdown-item @click="changeDropboxSearchType('callRoom')"
            >callRoom</b-dropdown-item
          >
          <b-dropdown-item @click="changeDropboxSearchType('text')"
            >text</b-dropdown-item
          >
        </b-dropdown>
        <p style="margin-top: 30px; margin-bottom: 30px">
          <b-input-group class="mt-1">
            <b-form-input
              v-model="keyword"
              @keyup.enter="getFeedsByEmail"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="info" @click="getFeedsByEmail">
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </p>

        <p
          v-if="isSearch"
          style="margin-top: 30px; margin-left: 10px; margin-bottom: 30px"
        >
          <b-input-group class="mt-1">
            <b-input-group-append>
              <b-button variant="info" @click="getDefaultSearch()">
                Default
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </p>
      </div>
    </b-col>
    <div style="display: flex; justify-content: flex-start; margin-left: 10px">
      <b-p v-if="isSearch">
        {{ dropdownSearchType }}: "{{ searchedKeyword }}" 로 검색한
        결과입니다.</b-p
      >
    </div>
    <div style="display:flex; width:100%; justify-content: flex-start;">
      <div style="display: flex; flex-direction: row; align-items: center">
        <b-dropdown id="dropdown-1" :text="dropdownHideType" class="m-md-2">
          <b-dropdown-item @click="changeHideDropboxHideType('none')"
            >none</b-dropdown-item
          >
          <b-dropdown-item @click="changeHideDropboxHideType('전체숨김')"
            >전체숨김</b-dropdown-item
          >
          <b-dropdown-item @click="changeHideDropboxHideType('신고누적숨김')"
            >신고누적숨김</b-dropdown-item
          >
          <b-dropdown-item @click="changeHideDropboxHideType('작외숨')"
            >작외숨</b-dropdown-item
          >
        </b-dropdown>
        <b-button class="mr-2" size="sm" @click="hideActionButton()">
          액션
        </b-button>
      </div>
      <div style="display: flex; flex-direction: row; align-items: center">
        <b-dropdown id="dropdown-1" :text="dropdownReportType" class="m-md-2">
          <b-dropdown-item v-for="text in this.feedReasonList" :key="text" @click="changeDropboxReportType({ text })"
            >{{text}}</b-dropdown-item
          >
        </b-dropdown>
        <b-button class="mr-2" size="sm" @click="createReportButton()">
          신고
        </b-button>
      </div>
    </div>

    <b-table hover :items="feedList" :fields="feedTableFields" small>
      <template #cell(user.uuid)="row">
        <b-form-checkbox
          v-model="status"
          name="checkbox-feedpost"  
          @input="checkboxChange(row.item.feedpost.feedpost_id, row.item.user.uuid)"
        >
        </b-form-checkbox>
      </template>

      <template #cell(feedpost.total_count_comments)="row">
        <b-button
          class="mr-2"
          size="sm"
          @click="
            getComments(row.item.feedpost.feedpost_id);
            $bvModal.show('commentModal');
          "
        >
          {{ row.item.feedpost.total_count_comments }}
        </b-button>
      </template>
      <template #cell(user.email)="row">
        <b-button
          class="mr-2"
          size="sm"
          @click="openAdminUserPage(row.item.user.email)"
        >
          {{ row.item.user.email }}
        </b-button>
      </template>
      <template #cell(feedpost.feedpost_id)="row">
        <b-button
          size="sm"
          class="mr-2"
          @click="openFeedContent(row.item.feedpost.feedpost_id, row.item)"
        >
          VIEW
        </b-button>
      </template>
      <template #cell(feedpost.created_at)="row">
        {{ getTimeStamp(row.item.feedpost.created_at) }}
      </template>

      <template #cell(feedpost.is_delete)="row">
        <b-icon :icon="row.item.feedpost.is_delete ? 'check-circle' : 'x-circle'" :variant="row.item.feedpost.is_delete ? 'success' : 'danger'"></b-icon>
      </template>

      <template #cell(feedpost.is_profile_exchange)="row">
        <b-form-checkbox
          v-model="isProfileExchange"
          name="checkbox-2"
          :value="row.item.feedpost.is_profile_exchange"
          disabled="true"
        >
        </b-form-checkbox>
      </template>
    </b-table>

    <b-modal id="commentModal" hide-footer size="xl">
      <template #modal-title>
        {{ feedOriginId }}
        <div style="font-size: small;">
          <b-span>선택된 댓글  {{ Object.keys(selectedComments).filter(
              (key) => selectedComments[key] === "accepted"
            ).length }}개</b-span>
            
          <b-dropdown
            id="dropdown-hide-comment"
            :text="commentDropdownHideType"
            class="m-md-2"
          >
            <b-dropdown-item @click="changecommentDropdownHideType('none')">none</b-dropdown-item>
            <b-dropdown-item @click="changecommentDropdownHideType('전체숨김')">전체숨김</b-dropdown-item>
            <b-dropdown-item @click="changecommentDropdownHideType('신고누적숨김')">신고누적숨김</b-dropdown-item>
            <b-dropdown-item @click="changecommentDropdownHideType('작외숨')">작외숨</b-dropdown-item>
          </b-dropdown>
          <b-button class="mr-2" size="sm" @click="hideCommentActionButton()">
            액션
          </b-button>
        </div>
        
      </template>
      <b-button @click="toggleSelectWholeComments()" style="margin-bottom: 30px;"> 전체 선택 </b-button>
      <b-table
        hover
        :items="comments"
        small
        :fields="commentTableFields"
        fixed
        style="word-break: break-all"
      >
      <template #cell(selected)="row">
        <b-form-checkbox
        v-model="selectedComments[row.item.id]"
            name="checkbox-comment"
            value="accepted"
            unchecked-value="not_accepted"
            @change="commentCheckboxChange(row.item.id)"
        ></b-form-checkbox>
        </template>
        <template #cell(id)="row">
          <b-button
            size="sm"
            class="mr-2"
            @click="openFeedComment(row.item.uuid, row.item.id)"
          >
            {{ row.item.id }}
          </b-button>
        </template>
        <template #cell(email)="row">
          <b-button
            size="sm"
            class="mr-2"
            @click="openAdminUserPage(row.item.email)"
          >
            {{ row.item.email.split("@")[0] }}
          </b-button>
        </template>
        <template #cell(is_delete)="row">
          <b-icon :icon="row.item.is_delete ? 'check-circle' : 'x-circle'" :variant="row.item.is_delete ? 'success' : 'danger'"></b-icon>
        </template>
        <template #cell(mentionedUser)="row">
          <b-button
          v-if = "row.item.mentioned_user"
            size="sm"
            class="mr-2"
            @click="openAdminUserPage(row.item.mentioned_user.email)">
            {{ row.item.mentioned_user.email.split("@")[0] }}
          </b-button>
          
        </template>
      </b-table>
      <b-button class="mt-3" block @click="$bvModal.hide('commentModal')"
        >닫기</b-button
      >
    </b-modal>

    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li
          class="page-item"
          v-for="item in createPageArray()"
          v-bind:key="item"
        >
          <a
            :style="[
              isPageButtonActive(item) ? { 'background-color': '#EC9E8E' } : {},
            ]"
            class="page-link"
            href="#"
            v-on:click="getFeeds(item)"
            >{{ item }}</a
          >
        </li>
        <!-- <li v-on:click="test()" class="page-item"><a class="page-link" href="#">Previous</a></li>
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">Next</a></li> -->
      </ul>
    </nav>
  </div>
</template>

<script>
import NavComponent from "./NavComponent.vue";

export default {
  name: "FeedContent",
  components: {
    NavComponent,
  },
  data() {
    return {
      feedTableFields: [
        { key: "user.uuid", label: "" },
        { key: "feedpost.feedpost_id", label: "feedpost_id" },
        { key: "user.email", label: "email" },
        { key: "feedpost.content.type", label: "content type" },
        { key: "feedpost.content.text", label: "text" },
        { key: "feedpost.total_count_comments", label: "댓글" },
        { key: "feedpost.total_count_likes", label: "좋아요" },
        { key: "feedpost.total_count_reports", label: "신고" },
        { key: "feedpost.hide", label: "숨김" },
        { key: "feedpost.is_delete", label: "삭제" },
        { key: "feedpost.created_at", label: "created_at" },
      ],
      feedList: [],
      feedReasonList: ["none"],
      commentTableFields: [
        {key:"selected", label:""},
        {key:"comment", label:"댓글 종류"},
        
        {key:"id", label:"id"},
        {key:"email", label:"작성자"},
        {key:"mentionedUser", label:"멘션된 유저"},
        {key:"content_type", label:"content type"},
        {key:"content", label:"text"},
        {key:"likesCount", label:"좋아요"},
        {key:"reportsCount", label:"신고"},
        {key:"is_delete", label:"삭제 여부"},
        {key:"hide", label:"숨김"},
        {key:"created_at", label:"작성일"},

      ],
      comments: [],
      feedOriginId: "", // 모달에서 선택된 피드의 id
      feedPage: 1,
      keyword: "",
      searchUserUuid: "",
      page: 0,
      loginCountry: this.$session.get("country"),
      apiBaseUrl: "",
      isSearch: false,
      selectedPosts: {},
      selectedComments: {},
      dropdownHideType: "none",
      commentDropdownHideType: "none",
      dropdownHideFilterType: "all",
      dropdownReportType: "none",
      dropdownSearchType: "email",
      isProfileExchange: true,
      searchedKeyword: "",
      hideCategory: {
        "none": "NONE",
        "전체숨김": "CS",
        "신고누적숨김": "REPORT",
        "작외숨": "OWN"
      },
      reportType: {
        "none": "NONE",
      }
    };
  },
  beforeMount() {
    this.apiBaseUrl = process.env.VUE_APP_FEED_SERVICE_URL;
    this.getFeeds(this.page);
    this.getReasonKey();
  },

  methods: {
    test() {
      alert("AA");
    },
    getHeader() {
      return {
              Authorization: this.$session.get("token"),
              uuid: this.$session.get("uuid"),
            }
    },
    isPageButtonActive(currentP) {
      return currentP == this.page ? true : false;
    },
    changeHideDropboxHideType(value) {
      this.dropdownHideType = value;
    },
    changecommentDropdownHideType(value) {
      this.commentDropdownHideType = value;
    },
    changeDropboxReportType(value) {
      this.dropdownReportType = value.text;
    },
    changeDropboxSearchType(value) {
      this.dropdownSearchType = value;

    },
    async changeHideCategoryFilterDropbox(value){
      this.dropdownHideFilterType = value
      await this.getFeeds(this.page)

    },
    async hideActionButton() {
      await this.$http
        .put(
          `${this.apiBaseUrl}/management/feedposts/batch`,
          {
            feedpostIds: Object.keys(this.selectedPosts),
            data: {
              hide: this.hideCategory[this.dropdownHideType],
            },
          },
          {
            headers:this.getHeader()
          }
        )
        .then((res) => {
          if (res.status === 200) {
            alert("success");
            this.selectedPosts = {};
          }
        })
        .catch((err) => {
          alert(err);
        });
      this.$router.go();
    },
    async createReportButton() {
      console.log(this.dropdownReportType, this.reportType[this.dropdownReportType], this.selectedPosts)
      if(this.reportType[this.dropdownReportType] == "NONE"){
        alert("신고 사유를 선택해주세요");
      } else if(Object.keys(this.selectedPosts).length>1) {
        alert("한개의 피드만 선택해주세요");
      } else{
        await this.$http
        .post(`${process.env.VUE_APP_API}/report/v4/report`, {
          "reason_key": this.reportType[this.dropdownReportType],
          "report_content": "관리자 신고",
          "domain": "FEED",
          "obj_id": Object.keys(this.selectedPosts)[0]
        }, {
          headers: {
            'Authorization': this.$session.get('token'),
            'Remote-user': Object.values(this.selectedPosts)[0]
          }
        }).then((res) => {
          if(res.status === 201) {
            alert("success");
            this.selectedPosts = {};
          }else if(res.status === 200) {
            alert("이미 관리자 신고처리 된 데이터입니다");
          }
        }).catch((err) => {
          alert(err);
        })
      }
      this.$router.go();
    },
    checkboxChange(post_id, uuid) {
      const exists = Object.keys(this.selectedPosts).includes(post_id);
      if (exists) {
        delete this.selectedPosts[post_id];
      } else {
        this.selectedPosts[post_id] = uuid;
      }
    },
    commentCheckboxChange(comment_id){
      
      const exists = Object.keys(this.selectedComments).includes(comment_id)
      
      if (exists) {
        this.$delete(this.selectedComments, 'not_accepted')
      } else {
        this.$set(this.selectedComments, comment_id, 
          'accepted'
        )
      }

    },
    createPageArray() {
      let list = [];

      let b = parseInt(this.page / 10);
      let f = false;
      for (var i = 0; i < 12; i++) {
        if (b >= 1 && !f) {
          f = true;
          list.push(b * 10 + i - 1);
        }
        list.push(b * 10 + i);
      }

      return list;
    },
    getTimeStamp(created_at) {
      return this.$moment(created_at).format("YY/MM/DD HH:mm");
    },
    openAdminUserPage(email) {
      window.open(
        `${process.env.VUE_APP_DJANGO_URL}/ko/yp/manage/service/admin/account/user/?q=${email}`
      );
    },
    getComments(activityId) {
      this.$http
        .get(`${this.apiBaseUrl}/management/feedposts/${activityId}/comments/`,
          {
            headers:this.getHeader()
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.feedOriginId = activityId;
            this.filterComments(res.data.data.comments);
            
          }
        })
        .catch((err) => {
          alert(err);
        }).finally(() =>{
          this.selectedComments = {};
        })
    },
    filterComments(commentData) {
      
      let comments = []

      commentData.forEach((comment) => {
        let bigCommentList = [];
        if (comment.children.length > 0) {
          comment.children.forEach((bigComment) => {
            bigCommentList = [
              {
                uuid: bigComment.user.uuid,
                comment: "┗",
                parent_id: comment.comment.comment_id,
                id: bigComment.comment.comment_id,
                content_type: bigComment.comment.content.type,
                content: bigComment.comment.content.text,
                likesCount: bigComment.comment.total_count_likes,
                reportsCount: bigComment.comment.total_count_reports,
                is_delete: bigComment.comment.is_delete,
                hide: bigComment.comment.hide,
                email: bigComment.user.email,
                created_at: this.$moment(bigComment.comment.created_at).format(
                  "YY/MM/DD_HH:mm"
                ),
                mentioned_user: bigComment.comment.mentioned_user,
              },
              ...bigCommentList,
            ];
          });
        }
        comments = [
          {
            comment: "origin",
            uuid: comment.user.uuid,
            id: comment.comment.comment_id,
            content_type: comment.comment.content.type,
            content: comment.comment.content.text,
            likesCount: comment.comment.total_count_likes,
            reportsCount: comment.comment.total_count_reports,
            is_delete: comment.comment.is_delete,
            hide: comment.comment.hide,
            email: comment.user.email,
            created_at: this.$moment(comment.comment.created_at).format(
              "YY/MM/DD_HH:mm"
            ),
          },
          ...bigCommentList,
          ...comments,
        ];
      });
      this.comments = [];
      
      this.$set(this, 'comments', comments) 
      console.log("comments",this.comments)
    },
    createUrl(page) {
      let apiURL = "";
      const baseUrl = `${this.apiBaseUrl}/management/feeds/search`
      const queryParams = new URLSearchParams();
      if (page) {
          queryParams.append('page', page)
      } else {
        queryParams.append('page', 0)
      }

      if (this.dropdownHideFilterType != 'all') {
        queryParams.append('hide[0]', this.hideCategory[this.dropdownHideFilterType])
      }

      if (this.isSearch) {
        const keyword = this.keyword.trim();
        switch (this.dropdownSearchType) {
          case "email":
            queryParams.append('email', keyword);
            break;
          case "callRoom":
            queryParams.append('callRoomId', keyword);
            break;
          case "text":
            queryParams.append('text', keyword);
            break;
        }
        apiURL = `${baseUrl}?${queryParams.toString()}`
      } else {
        apiURL = `${this.apiBaseUrl}/management/feeds/by-country?${queryParams.toString()}`;
      }
      return apiURL
    },

    async getReasonKey() {
      this.$http
      .get(process.env.VUE_APP_API + "/management/report/control_reasons/", {
        headers: { Authorization: this.$session.get("token") },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("reason:", res.data.data);
          res.data.data.forEach((reason) => {
            let translatedText = "";
            switch (this.loginCountry){
              case "EN":
                translatedText = reason.description_en;
                break;
              case "JP":
                translatedText = reason.description_ja;
                break;
              default:
                translatedText = reason.description_ko;
                break;
            }
            if (reason.key.startsWith("FEED")) {
              this.feedReasonList.push(translatedText);
              this.reportType[translatedText] = reason.key;
            }
          });
          console.log(this.feedReasonList, this.reportType)
        }
      })
      .catch((err) => {
        alert("report reasons Error" + err);
      });
    },

    async getFeeds(page) {

      const url = this.createUrl(page)
      await this.$http
        .get(url, { headers:this.getHeader()})
        .then((res) => {
          this.page = page;

          let items = [];
          if (Object.keys(res.data.data).length === 0) {
            res;
          }
          for (let it of res.data.data.feed_list) {
            if (it != null) {
              if (it.feedpost.access === "PUBLIC") {
                it.feedpost.access = "전체";
              } else if (it.feedpost.access === "PRIVATE") {
                it.feedpost.access = "팔로우";
              }

              if (it.feedpost.content.type === "IMAGE") {
                it.feedpost.content.type = "사진";
              } else if (it.feedpost.content.type === "AUDIO") {
                it.feedpost.content.type = "음성";
              } else if (it.feedpost.content.type === "CALL_ROOM") {
                it.feedpost.content.type = "룸공유";
              } else if (it.feedpost.content.type === "NORMAL") {
                it.feedpost.content.type = "일반";
              }

              if (it.feedpost.hide == "CS") {
                it.feedpost.hide = "전체숨김";
              } else if (it.feedpost.hide == "REPORT") {
                it.feedpost.hide = "신고누적숨김";
              } else if (it.feedpost.hide == "OWN") {
                it.feedpost.hide = "작외숨";
              } else if (it.feedpost.hide == "NONE") {
                it.feedpost.hide = "none";
              }

              if (it.feedpost.content.text.length >= 70) {
                it.feedpost.content.text =
                  it.feedpost.content.text.substring(0, 70) + "...";
              }

              items.push(it);
            }
          }

          this.feedList = items;
          console.log(
            "🚀 ~ file: FeedContentSearch.vue ~ line 432 ~ getFeeds ~ items",
            items
          );
        })
        .catch((err) => {
          alert(err);
        });
    },
    toggleSelectWholeComments() {
      if (Object.keys(this.selectedComments).length === this.comments.length) {
        this.selectedComments = {};
      } else {
        this.comments.forEach((comment) => {
          this.$set(this.selectedComments, comment.id, "accepted");
        })
      }
    
  
    },
    async getFeedsByEmail() {
      if (this.keyword === "") {
        return
      }

      if (!this.isSearch) {
        this.page = 0;
        this.isSearch = true;
      }
      this.getFeeds(this.page);
      this.searchedKeyword = this.keyword;
    },

    async getDefaultSearch() {
      this.dropdownSearchType = "email";
      this.dropdownHideFilterType = "all";
      this.searchedKeyword = "";
      this.keyword = "";
      this.isSearch = false;
      this.page = 0;
      this.getFeeds(this.page);
    },
    openFeedContent(id, feed) {
      const routeData = this.$router.resolve({
        name: "feedContent",
        params: { id: id, feed: feed },
      });
      window.open(routeData.href, "_blank");
    },
    openFeedComment(userId, commentId) {
      console.log("F", userId, commentId);
      const routeData = this.$router.resolve({
        name: "feedComment",
        params: { user_id: userId, comment_id: commentId },
      });
      console.log("FFFF", routeData);
      window.open(routeData.href, "_blank");
    }, 
    async hideCommentActionButton() {
      const conmmentIds =Object.keys(this.selectedComments)
      await this.$http
        .put(
          `${this.apiBaseUrl}/management/comments/batch`,
          {
            commentIds: conmmentIds,
            data: {
              hide: this.hideCategory[this.commentDropdownHideType],
            },
          },
          {
            headers:this.getHeader()
          }
        )
        .then((res) => {
          if (res.status === 200) {
            alert("success");
            this.selectedComments = {};
            this.getComments(this.feedOriginId)
          }
        })
        .catch((err) => {
          alert(err);
        });
      
    },
  },
};
</script>

<style scoped>
.nameFields {
  font-weight: bold;
  text-align: right;
}

.feedData {
  text-align: left;
}
</style>
