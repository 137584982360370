import Vue from 'vue'
import App from './App.vue'
import router from '../router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSession from 'vue-session'
import axios from 'axios'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import {store} from "./store"
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'


Vue.use(VueMoment, {
  moment
})
Vue.use(VueToast)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false
Vue.prototype.$http = axios
var sessionOptions = {
  persist: true
}
Vue.use(VueSession, sessionOptions)

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
