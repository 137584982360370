<template>
    <div id="app">
        <NavComponent />
    </div>
</template>

<script>
import NavComponent from './NavComponent.vue'

export default {
    name: 'Home',
    components: {
        NavComponent
    },
    data() {
        return {}
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
 
.container {
    text-align: center;
}
</style>
