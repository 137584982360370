<template>
  <div>
    <!--<b-button @click="changeState()" variant="outline-info" size="sm" style="position: absolute; right: 15px; top:15px">{{$store.state.env}}</b-button>-->
    <b-button
      disabled
      @click="changeState()"
      variant="outline-info"
      size="sm"
      style="position: absolute; right: 15px; top: 15px"
      >development</b-button
    >
    <b-container id="loginContainer" style="max-width: 500px">
      <b-row>
        <b-col>
          <b-img
            src="https://cdn.imweb.me/upload/S202004174e0a0bb51c9d6/f1082f0d4e37a.png"
            class="mb-5"
          ></b-img>
          <div
            class="alert alert-danger text-left"
            role="alert"
            v-if="message != ''"
          >
            {{ message }}
          </div>
          <div>
            <b-form-input
              type="email"
              size="m"
              id="loginInput"
              v-model="user.email"
              placeholder="E-mail"
            ></b-form-input>
            <b-form-input
              v-on:keyup.enter="login"
              size="m"
              type="password"
              id="loginInput"
              v-model="user.password"
              placeholder="Password"
            ></b-form-input>
          </div>
          <b-button variant="primary" id="loginBtn" v-on:click="login" block
            >Sign In</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "App",
  data: function () {
    return {
      message: "",
      user: {
        email: "",
        password: "",
      },
    };
  },
  beforeCreate: function () {
    if (this.$session.exists()) {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    login: function (event) {
      if (event) {
        this.message = "";
        this.$http
          .post(process.env.VUE_APP_API + "/account/v2/login/email/", {
            email: this.user.email,
            password: this.user.password,
            fcmToken: "TEST",
          })
          .then((res) => {
            const ENCountries = ["US", "GB", "AU", "CA", "SG", "MY"];
            const CNCountries = ["TW", "HK"];
            if (res.status === 200 && res.data.code == 1) {
              this.$session.start();
              this.$session.set("token", "Token " + res.data.token);
              this.$session.set("uuid", res.data.user.uuid);
              //   console.log(res.data);
              // this.$session.set(
              //   "token",
              //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiMDVkNjJjNGM2NGY3MTFlYTlhMTE0MjAxMGE5MjAwYTAifQ.XxM6w0J4u0znZtQeo2-SwKS1MTRnQAnXLL0nHHLW8Fc"
              // );
              // this.$session.set('token', 'f94e1b73a7828b4b903b758f729fb9ab088abaa5')
              // this.$session.set("uuid", "05d62c4c64f711ea9a1142010a9200a0");
              if (ENCountries.includes(res.data.user.country)) {
                this.$session.set("country", "EN");
              } 
              else if (CNCountries.includes(res.data.user.country)) {
                this.$session.set("country", "EN");
              }
              else {
                this.$session.set("country", res.data.user.country);
              }
              this.$router.push({ name: "home" });
            } else this.message = "Login Failed";
          })
          .catch((err) => {
            this.message = err;
          });
      }
    },
    changeState() {
      if (this.$store.state.env == "development") {
        alert("production으로 변경되었습니다.");
        this.$store.commit("changeEnv", {
          env: "production",
          api: "http://connectingserver-env.eba-i3pwpmvk.ap-northeast-2.elasticbeanstalk.com",
        });
        history.go(0);
      } else if (this.$store.state.env == "production") {
        alert("development으로 변경되었습니다.");
        this.$store.commit("changeEnv", {
          env: "development",
          api: "http://devconnectingserver-env.eba-xf39haa4.ap-northeast-2.elasticbeanstalk.com",
        });
        history.go(0);
      }
    },
  },
};
</script>

<style scoped>
body {
  background: #f8f8f8;
}

.container {
  text-align: center;
}

#loginContainer {
  margin-top: 15%;
}

#loginLabel {
  margin-bottom: -5px;
}

#loginBtn {
  background: rgb(0, 191, 161);
  background: -moz-linear-gradient(
    135deg,
    rgba(0, 191, 161, 1) 0%,
    rgba(0, 144, 194, 1) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    rgba(0, 191, 161, 1) 0%,
    rgba(0, 144, 194, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(0, 191, 161, 1) 0%,
    rgba(0, 144, 194, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00bfa1", endColorstr="#0090c2", GradientType=1);
  border: rgb(0, 191, 161);
  border: -moz-linear-gradient(
    135deg,
    rgba(0, 191, 161, 1) 0%,
    rgba(0, 144, 194, 1) 100%
  );
  border: -webkit-linear-gradient(
    135deg,
    rgba(0, 191, 161, 1) 0%,
    rgba(0, 144, 194, 1) 100%
  );
  border: linear-gradient(
    135deg,
    rgba(0, 191, 161, 1) 0%,
    rgba(0, 144, 194, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00bfa1", endColorstr="#0090c2", GradientType=1);
}

#loginInput {
  margin-bottom: 20px;
}

#loginCard {
  margin-top: 20px;
}
</style>
