<template>
    <div id="app">
        <NavComponent />
        <b-container>
            <b-row>
                <b-col cols="1">
                </b-col>
                <b-col style="text-align:-webkit-center;">
                    <p v-if="data==''">
                                <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
                            </p>
                    <b-card style="max-width:600px">
                        <div>
                            <b-carousel ref="myCarousel" id="carousel-1" v-model="slide" :interval="0" controls :no-wrap=true>
                                <!-- Text slides with image -->
    
                                <b-carousel-slide img-src="https://picsum.photos/480/480/?image=52"></b-carousel-slide>
                                <b-carousel-slide img-src="https://picsum.photos/480/480/?image=53"></b-carousel-slide>
                                <b-carousel-slide img-src="https://picsum.photos/480/480/?image=54"></b-carousel-slide>
    
                            </b-carousel>
                        </div>
                        <b-card-text style="text-align:left">
                            <b-row class="userInfo">
                                <b-col>이메일</b-col>
                                <b-col>{{user.email}}</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>닉네임</b-col>
                                <b-col>{{user.nickName}}</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>나이</b-col>
                                <b-col>{{user.age}}</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>재가입</b-col>
                                <b-col>asd</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>프로필 등급</b-col>
                                <b-col>qwe</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>누적 결제</b-col>
                                <b-col>6</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>누적 출석</b-col>
                                <b-col>6</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>최근 결제 날짜</b-col>
                                <b-col>6</b-col>
                            </b-row>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col cols="1">
                </b-col>
            </b-row>
    
            <b-row>
                <b-col>
                    <b-button-group>
                        <b-button size="lg" class="resultBtn" pill>A</b-button>
                        <b-button size="lg" class="resultBtn" pill>B</b-button>
                        <b-button size="lg" class="resultBtn" pill>C</b-button>
                        <b-button size="lg" class="resultBtn" pill>D</b-button>
                        <b-button size="sm" class="resultBtn" pill @click="testModalShow = !testModalShow">Black</b-button>
    
                        <b-modal v-model="testModalShow" hide-footer hide-header>
                            <b-row>
                                <b-col cols="3">
                                </b-col>
                                <b-col cols="5" style="margin:auto">
                                    <draggable :list="list" :disabled="!enabled" ghost-class="ghost" @start="dragging = true" @end="dragging = false">
                                        <p style="padding: unset" v-for="element in list" :key="element.src">
                                            <b-img style="width:100%" :src="element.src"></b-img>
                                        </p>
                                    </draggable>
                                </b-col>
                                <b-col cols="3">
                                    <p v-for="element in list" :key="element.id" style="margin-bottom:165%">
                                        <b-button pill variant="outline-dark" @click="test(element.id)">
                                            X
                                        </b-button>
                                        <br>
                                    </p>
                                </b-col>
                            </b-row>
                            <div class="d-block text-center lg" style="margin-top: 10px">
                                <b-button variant="success" pill @click="testModalShow = !testModalShow" @keyup.enter="testModalShow = !testModalShow" style="margin-right:15px">
                                    확인
                                </b-button>
                                <b-button variant="info" pill style="margin-right:15px; margin-left:15px;" @click="recoverOld()">
                                    복구
                                </b-button>
                                <b-button variant="danger" pill @click="testModalShow = !testModalShow" @keyup.esc="testModalShow = !testModalShow" style="margin-left:15px">
                                    취소
                                </b-button>
                            </div>
                        </b-modal>
    
                    </b-button-group>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import NavComponent from './NavComponent.vue'
import draggable from 'vuedraggable'

export default {
    name: 'MaleRank',
    components: {
        NavComponent,
        draggable
    },
    beforeDestroy(){
        clearTimeout(this.refreshtimer)
    },
    beforeCreate() {
        this.$http.get("http://api.yplabs.net/management/account/get_approval_members/", {
                params: {
                    "sex": "F",
                    "page": 0
                },
                headers: { 'Authorization': 'Token ' + this.$session.get('token') },
            }).then((res) => {
                if (res.status === 200) {
                    if (res.data.data.length != 0) {
                        //this.data = res.data.data
                        //this.updateInfo()
                        //console.log(this.data)
                        this.data = null
                    } else this.data = null
                }
            })
            .catch((err) => {
                this.data = null
                alert(err)
            })
    },
    computed: {
        draggingInfo() {
            return this.dragging ? "under drag" : "";
        }
    },
    watch: {
        data: function() {
            if (this.data == null) {
                this.refreshtimer = setInterval(() => {
                    this.$router.go(0)
                    clearTimeout(this.refreshtimer)
                }, 5000)
            }
        }
    },
    data() {
        return {
            refreshtimer: null,
            user: {
                id: '',
                email: '',
                nickName: '',
                age: '',
                imageList: null,
            },
            slide: 0,
            sliding: null,
            testModalShow: false,
            list: [
                /*
                {src : '', id: 0}
                */
            ],
            oldList: [
                /*
                {src : '', id: 0}
                */
            ],
            dragging: false,
            enabled: true,
            data: ''
        }
    },
    methods: {
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        recoverOld() {
            this.list = this.oldList.slice(0, this.oldList.length)
        },
        updateInfo() {
            this.oldList = []
            this.list = []
            this.user.email = this.data[0].email
            this.user.nickName = this.data[0].nickname
            this.user.age = this.data[0].age
            this.user.imageList = this.data[0].profileImages
            this.updateImageList()
        },
        updateImageList() {
            let src
            for (let i = 0; i < this.data[0].profileImages.length; i++) {
                src = this.user.imageList[i].requestImage
                this.oldList.push({
                    src: src,
                    id: i
                })
            }
            this.recoverOld()
        },
        test(index) {
            this.list = this.list.filter((element) => element.id != index)
        }
    }
}
</script>

<style scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.resultBtn {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
}

.userInfo {
    border-bottom: groove;
    border-width: 1px;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
