<template>
    <div id="app">
        <NavComponent />
        <b-container style="margin-top:100px">
            <b-row>
                <b-col style="text-align:-webkit-center;">
                    <b-card style="max-width:600px">
                        <div>
                            <b-carousel ref="myCarousel" id="carousel-1" v-model="slide" :interval="0" controls :no-wrap=true @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
                                <!-- Text slides with image -->
                                <b-carousel-slide v-for="(image, index) in user.imageList" v-bind:key="index" :img-src="image.requestImage"></b-carousel-slide>
    
                            </b-carousel>
                        </div>
                        <b-card-text style="text-align:left">
                            <p v-if="data==''" style="text-align:center">
                                <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
                            </p>
                            <p v-if="this.data==null" style="text-align : center">
                                {{contentString.noList[loginCountry]}} <br>{{contentString.refresh[loginCountry]}} <br>{{countDown}}
                            </p>
                            <div v-else-if="this.data!=null">
                                <b-row class="userInfo">
                                    <b-col><strong>{{contentString.email[loginCountry]}}</strong></b-col>
                                    <b-col style="word-break: break-all">{{user.email}}</b-col>
                                </b-row>
                                <b-row class="userInfo">
                                    <b-col><strong>{{contentString.nickname[loginCountry]}}</strong></b-col>
                                    <b-col>{{user.nickName}}</b-col>
                                </b-row>
                                <b-row v-if="newNickName != ''" class="userInfo">
                                    <b-col><strong>{{contentString.nicknameChanged[loginCountry]}}</strong></b-col>
                                    <b-col>{{newNickName}}</b-col>
                                </b-row>
                                <b-row class="userInfo">
                                    <b-col><strong>{{contentString.age[loginCountry]}}</strong></b-col>
                                    <b-col>{{user.age}}</b-col>
                                </b-row>
                                <b-row class="userInfo">
                                    <b-col><strong>{{contentString.dateJoined[loginCountry]}}</strong></b-col>
                                    <b-col>{{user.dateJoined | moment("YYYY-MM-DD HH:mm:ss")}}</b-col>
                                </b-row>
                                <b-row class="userInfo">
                                    <b-col><strong>{{contentString.reEntrance[loginCountry]}}</strong></b-col>
                                    <b-col>{{user.reEntrance}}</b-col>
                                </b-row>
                                <b-row class="userInfo">
                                    <b-col><strong>{{contentString.cash[loginCountry]}}</strong></b-col>
                                    <b-col>{{user.cash}}</b-col>
                                </b-row>
                                <b-row v-if="newCash != ''" class="userInfo">
                                    <b-col><strong>{{contentString.cashChanged[loginCountry]}}</strong></b-col>
                                    <b-col>{{newCash}}</b-col>
                                </b-row>
                            </div>
                        </b-card-text>
    
                        <b-button-group size="sm">
                            <b-button variant="outline-info" v-if="loginCountry!='JP'" class="changeBtn" @click="nickNameModalShow = !nickNameModalShow" pill>{{contentString.changingNickname[loginCountry]}}</b-button>
                            <b-button variant="outline-info" v-if="loginCountry=='JP'" class="jpChangeBtn" @click="nickNameModalShow = !nickNameModalShow" pill>{{contentString.changingNickname[loginCountry]}}</b-button>
                            <b-modal v-model="nickNameModalShow" hide-footer hide-header style="text-align: center">
                                <p>
                                    {{contentString.originalNickname[loginCountry]}} : {{user.nickName}}
                                </p>
                                <p>
                                    <b-form-input v-model="newNickName" :placeholder="contentString.newNickNamePlaceholder[loginCountry]"></b-form-input>
                                </p>
                                <div class="d-block text-center lg">
                                    <b-button variant="success" pill @click="nickNameModalShow = !nickNameModalShow" @keyup.enter="nickNameModalShow = !nickNameModalShow" style="margin-right:15px">
                                        {{contentString.accept[loginCountry]}}
                                    </b-button>
                                    <b-button variant="danger" pill @click="nickNameModalShow = !nickNameModalShow, newNickName = ''" @keyup.esc="nickNameModalShow = !nickNameModalShow" style="margin-left:15px">
                                        {{contentString.reject[loginCountry]}}
                                    </b-button>
                                </div>
                            </b-modal>
                            <!-- new nickname modal -->
                            <b-button variant="outline-info" v-if="loginCountry!='JP'" class="changeBtn" @click="changeProfileImageModalShow = !changeProfileImageModalShow" pill>{{contentString.changingProfOrder[loginCountry]}}</b-button>
                            <b-button variant="outline-info" v-if="loginCountry=='JP'" class="jpChangeBtn" @click="changeProfileImageModalShow = !changeProfileImageModalShow" pill>{{contentString.changingProfOrder[loginCountry]}}</b-button>
                            <b-modal v-model="changeProfileImageModalShow" hide-footer hide-header>
                                <b-row>
                                    <b-col cols="3">
                                    </b-col>
                                    <b-col cols="5" style="margin:auto">
                                        <draggable :list="list" :disabled="!enabled" ghost-class="ghost" @start="dragging = true" @end="dragging = false">
                                            <p style="padding: unset" v-for="element in list" :key="element.src">
                                                <b-img style="width:100%" :src="element.src"></b-img>
                                            </p>
                                        </draggable>
                                    </b-col>
                                    <b-col cols="3">
                                        <!--
                                        <p v-for="element in list" :key="element.id" style="margin-bottom:165%">
                                            <b-button pill variant="outline-dark" @click="deleteImage(element.id)">
                                                X
                                            </b-button>
                                            <br>
                                        </p>
                                        -->
                                    </b-col>
                                </b-row>
                                <div class="d-block text-center lg" style="margin-top: 10px">
                                    <b-button variant="success" pill @click="changeProfileImageModalShow = !changeProfileImageModalShow" @keyup.enter="changeProfileImageModalShow = !changeProfileImageModalShow" style="margin-right:15px">
                                        {{contentString.accept[loginCountry]}}
                                    </b-button>
                                    <b-button variant="info" pill style="margin-right:15px; margin-left:15px;" @click="recoverOld()">
                                        {{contentString.restore[loginCountry]}}
                                    </b-button>
                                    <b-button variant="danger" pill @click="changeProfileImageModalShow = !changeProfileImageModalShow" @keyup.esc="changeProfileImageModalShow = !changeProfileImageModalShow" style="margin-left:15px">
                                        {{contentString.reject[loginCountry]}}
                                    </b-button>
                                </div>
                            </b-modal>
                            <!--change profile Modal-->
    
                            <b-button variant="outline-info" v-if="loginCountry!='JP'" class="changeBtn" @click="changeCashModalShow = !changeCashModalShow" pill>{{contentString.changingCash[loginCountry]}}</b-button>
                            <b-button variant="outline-info" v-if="loginCountry=='JP'" class="jpChangeBtn" @click="changeCashModalShow = !changeCashModalShow" pill>{{contentString.changingCash[loginCountry]}}</b-button>
                            <b-modal v-model="changeCashModalShow" hide-footer hide-header style="text-align: center">
                                <p>
                                    {{contentString.originalCash[loginCountry]}} : {{user.cash}}
                                </p>
                                <p>
                                    <b-form-input v-model="newCash" :placeholder="contentString.newCashPlaceholder[loginCountry]"></b-form-input>
                                </p>
                                <div class="d-block text-center lg">
                                    <b-button variant="success" pill @click="changeCashModalShow = !changeCashModalShow" @keyup.enter="changeCashModalShow = !changeCashModalShow" style="margin-right:15px">
                                        {{contentString.accept[loginCountry]}}
                                    </b-button>
                                    <b-button variant="danger" pill @click="changeCashModalShow = !changeCashModalShow, newCash = ''" @keyup.esc="changeCashModalShow = !changeCashModalShow" style="margin-left:15px">
                                        {{contentString.reject[loginCountry]}}
                                    </b-button>
                                </div>
                            </b-modal>
                            <!-- new cash modal -->
    
                        </b-button-group>
                        <b-row>
                            <b-col>
                                <b-button-group>
                                    <b-button variant="outline-success" @click="updateApproval('A')" class="resultBtn" pill>A</b-button>
                                    <b-button variant="outline-success" @click="updateApproval('B')" class="resultBtn" pill>B</b-button>
                                    <b-button variant="outline-success" @click="updateApproval('C')" class="resultBtn" pill>C</b-button>
                                    <b-button variant="outline-success" @click="updateApproval('D')" class="resultBtn" pill>D</b-button>
                                    <b-button variant="outline-danger" @click="updateApproval('BLACK')" size="sm" class="resultBtn" pill>BLK</b-button>
                                    <b-button variant="outline-danger" @click="blackTypeModalShow = !blackTypeModalShow" class="resultBtn" pill>X</b-button>
                                    <b-modal v-model="blackTypeModalShow" hide-footer hide-header style="text-align: center">
                                        <h5 style="text-align: center">
                                            {{contentString.rejectReason[loginCountry]}}
                                        </h5>
                                        <b-form-group>
                                            <b-form-checkbox-group size="sm" v-model="selected" :options="options" button-variant="outline-primary" stacked buttons style="width:100%; word-break: keep-all; word-wrap: normal;"></b-form-checkbox-group>
                                        </b-form-group>
                                        <div class="d-block text-center lg">
                                            <b-button variant="success" pill @click="blackTypeModalShow = !blackTypeModalShow, updateApprovalBlackType()" @keyup.enter="blackTypeModalShow = !blackTypeModalShow, updateApprovalBlackType()" style="margin-right:15px">
                                                {{contentString.accept[loginCountry]}}
                                            </b-button>
                                            <b-button variant="danger" pill @click="blackTypeModalShow = !blackTypeModalShow, selected = []" @keyup.esc="blackTypeModalShow = !blackTypeModalShow, selected = []" style="margin-left:15px">
                                                {{contentString.reject[loginCountry]}}
                                            </b-button>
                                        </div>
                                    </b-modal>
                                </b-button-group>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import NavComponent from './NavComponent.vue'
import draggable from 'vuedraggable'
import ApprovalJson from '../assets/string/approval.json'
export default {
    name: 'MaleApproval',
    components: {
        NavComponent,
        draggable
    },
    watch: {
        selected: function() {
            if (this.selected.length > 1)
                this.selected.splice(0, 1)
        },
        data: function() {
            if (this.data == null) {
                this.refreshtimer = setInterval(() => {
                    this.$router.go(0)
                    clearTimeout(this.refreshtimer)
                }, 5000)
                this.refreshCountDown()
            }
        }
    },
    data() {
        return {
            refreshtimer: null,
            slide: 0,
            sliding: null,
            data: '',
            user: {
                id: '',
                email: '',
                nickName: '',
                age: '',
                dateJoined: '',
                reEntrance: '',
                cash: '',
                imageList: null
            },
            newNickName: '',
            newCash: '',
            list: [
                /*
                {src : '', id: 0, imageId: 0}
                */
            ],
            oldImageList: [
                /*
                {src : '', id: 0, imageId: 0}
                */
            ],
            dragging: false,
            enabled: true,
            nickNameModalShow: false,
            changeCashModalShow: false,
            changeProfileImageModalShow: false,
            blackTypeModalShow: false,
            selected: [], // Must be an array reference!
            options: [],//ApprovalJson.inapprovalReason,
            loginCountry: this.$session.get('country'),
            contentString: ApprovalJson.contentString,
            countDown: 5
        }
    },
    beforeDestroy() {
        clearTimeout(this.refreshtimer)
    },
    beforeCreate() {
        this.$http.get(process.env.VUE_APP_API + "/management/account/members/wait_approvals/", {
                params: {
                    "sex": "M",
                    "page": 0
                },
                headers: { 'Authorization': this.$session.get('token') },
            }).then((res) => {
                if (res.status === 200) {
                    if (res.data.data.length != 0) {

                        console.log(res.data)
                        this.data = res.data.data
                        this.updateInfo()
                    } else this.data = null
                }
            })
            .catch((err) => {
                this.data = null
                alert(err)
            })
    },
    beforeMount() {
        let country = "description_ko"
        if(this.loginCountry == 'JP'){
            country = "description_ja"
        }
        else if(this.loginCountry == 'EN'){
            country = "description_en"
        }
        else if(this.loginCountry == 'MENA'){
            country = "description_en"
        }
        // TODO
        else if(this.loginCountry == 'CN'){
            country = "description_en"
        }
        this.$http.get(process.env.VUE_APP_API + "/account/v2/inapproval-types/", {
                headers: { 'Authorization': this.$session.get('token') },
            }).then((res) => {
                if (res.status === 200) {
                    res.data.forEach(data => {
                            this.options.push({
                                value: data.type,
                                text: data[country]
                        })
                    })
                }
            })
            .catch((err) => {
                alert(err)
            })
    },
    methods: {
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        updateInfo() {
            this.user.id = this.data[0].id
            this.user.email = this.data[0].email
            this.user.nickName = this.data[0].nickname
            this.user.age = this.data[0].age
            this.user.dateJoined = this.data[0].date_joined
            this.user.reEntrance = this.data[0].reentrance
            this.user.cash = this.data[0].cash
            this.user.imageList = this.data[0].profileImages
            this.newNickName = ''
            this.newCash = ''
            this.slide = 0
            this.updateImageList()
        },
        updateApproval(rank) {
            this.$http.post(process.env.VUE_APP_API + "/management/account/members/wait_approvals/",
                this.checkOptions(rank), {
                    headers: { 'Authorization': this.$session.get('token') }
                }).then((res) => {
                if (res.status === 200 && this.data != null) {
                    this.$toast.success(this.contentString.successMsg[this.loginCountry], {
                        position: 'top',
                        duration: 1200
                    })
                } else if (this.data == null) {
                    this.$toast.warning(this.contentString.noListMsg[this.loginCountry], {
                        position: 'top',
                        duration: 1200
                    })
                }
                // update, clean lists
                if (this.data.length > 1) {
                    this.data.splice(0, 1)
                    this.updateInfo()
                    this.selected = []
                } else {
                    this.data.splice(0, 1)
                    this.data = null
                    this.selected = []
                    this.wip()
                }
            }).catch((err) => {
                console.log(err)
                this.$toast.error(this.contentString.errorMsg[this.loginCountry], {
                    position: 'top',
                    duration: 1200
                })
            })
        },
        updateApprovalBlackType() {
            this.$http.post(process.env.VUE_APP_API + "/management/account/members/wait_approvals/", {
                "id": this.user.id,
                "otherOption": 0,
                "blackType": this.selected[0]
            }, {
                headers: { 'Authorization': this.$session.get('token') }
            }).then((res) => {
                if (res.status === 200 && this.data != null) {
                    this.$toast.success(this.contentString.successMsg[this.loginCountry], {
                        position: 'top',
                        duration: 1200
                    })
                } else if (this.data == null) {
                    this.$toast.warning(this.contentString.noListMsg[this.loginCountry], {
                        position: 'top',
                        duration: 1200
                    })
                }
                // update, clean lists
                if (this.data.length > 1) {
                    this.data.splice(0, 1)
                    this.updateInfo()
                    this.selected = []
                } else {
                    this.data.splice(0, 1)
                    this.data = null
                    this.selected = []
                    this.wip()
                }
            }).catch((err) => {
                console.log(err)
                this.$toast.error(this.contentString.errorMsg[this.loginCountry], {
                    position: 'top',
                    duration: 1200
                })
            })
        },
        checkOptions(rank) {
            if (!this.checkChangingImages()) { // profile image changed
                this.changeImageSrc()
                if (rank == 'BLACK') {
                    return {
                        "id": this.user.id,
                        "blackServer": true,
                        "profileImages": this.user.imageList
                    }
                } else if (this.newNickName != '' && this.newCash != '') {
                    return {
                        "id": this.user.id,
                        "profileLevel": rank,
                        "nickname": this.newNickName,
                        "cash": Number(this.newCash),
                        "profileImages": this.user.imageList
                    }
                } else if (this.newNickName != '' && this.newCash == '') {
                    return {
                        "id": this.user.id,
                        "profileLevel": rank,
                        "nickname": this.newNickName,
                        "profileImages": this.user.imageList
                    }
                } else if (this.newNickName == '' && this.newCash != '') {
                    return {
                        "id": this.user.id,
                        "profileLevel": rank,
                        "cash": Number(this.newCash),
                        "profileImages": this.user.imageList
                    }
                } else return {
                    "id": this.user.id,
                    "profileLevel": rank,
                    "profileImages": this.user.imageList
                }
            } else { // profile image not changed
                if (rank == 'BLACK') {
                    return {
                        "id": this.user.id,
                        "blackServer": true
                    }
                } else if (this.newNickName != '' && this.newCash != '') {
                    return {
                        "id": this.user.id,
                        "profileLevel": rank,
                        "nickname": this.newNickName,
                        "cash": Number(this.newCash)
                    }
                } else if (this.newNickName != '' && this.newCash == '') {
                    return {
                        "id": this.user.id,
                        "profileLevel": rank,
                        "nickname": this.newNickName
                    }
                } else if (this.newNickName == '' && this.newCash != '') {
                    return {
                        "id": this.user.id,
                        "profileLevel": rank,
                        "cash": Number(this.newCash)
                    }
                } else return {
                    "id": this.user.id,
                    "profileLevel": rank
                }
            }
        },
        wip() {
            this.$router.go(0)
        },
        updateImageList() {
            this.oldImageList = []
            for (let i = 0; i < this.data[0].profileImages.length; i++) {
                this.oldImageList.push({
                    approval: this.user.imageList[i].approval,
                    image: this.user.imageList[i].image,
                    src: this.user.imageList[i].requestImage,
                    id: i,
                    imageId: this.user.imageList[i].id,
                })
            }
            this.recoverOld()
        },
        deleteImage(index) {
            this.list = this.list.filter((element) => element.id != index)
        },
        recoverOld() {
            this.list = []
            this.list = this.oldImageList.slice(0, this.oldImageList.length)
        },
        checkChangingImages() {
            if (this.list.length != this.oldImageList.length) {
                return false
            }
            for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].id != this.oldImageList[i].id) {
                    return false
                }
            }
            return true
        },
        changeImageSrc() {
            for (let i = 0; i < this.list.length; i++) {
                this.user.imageList[i].requestImage = this.list[i].src
                this.user.imageList[i].approval = this.list[i].approval
                this.user.imageList[i].image = this.list[i].image
            }
            this.user.imageList.splice(this.list.length, this.user.imageList.length)
        },
        refreshCountDown() {
            setTimeout(() => {
                this.countDown--
                    setTimeout(() => {
                        this.countDown--
                            setTimeout(() => {
                                this.countDown--
                                    setTimeout(() => {
                                        this.countDown--
                                    }, 1000)
                            }, 1000)
                    }, 1000)
            }, 1000)
        }
    }
}
</script>

<style scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
.resultBtn {
    margin: 5px 10px 0px 10px;
    word-break: keep-all;
    word-wrap: normal;
}
.changeBtn {
    margin: 5px 10px 0px 10px;
    word-break: keep-all;
}
.jpChangeBtn {
    margin: 5px 10px 0px 10px;
    word-break: break-all;
}
.userInfo {
    border-bottom: groove;
    border-width: 1px;
    font-size: small;
}
.drop-zone {
    background-color: #eee;
    margin-bottom: 10px;
    padding: 10px;
}
.drag-el {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 5px;
}
</style>