import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
      env : 'development',
      api : 'http://connectingserver-env.eba-i3pwpmvk.ap-northeast-2.elasticbeanstalk.com'
  },
  mutations:{
      changeEnv: function (state, data){
          state.env = data.env
          state.api = data.api
      }
  },
  plugins: [createPersistedState()]
});