<template>
    <div id="app">
        <NavComponent />
        <b-container>
            <b-row>
                <b-col cols="1">
                </b-col>
                <b-col style="text-align:-webkit-center;">
                    <b-card style="max-width:600px">
                        <div>
                            <b-carousel ref="myCarousel" id="carousel-1" v-model="slide" :interval="0" controls :no-wrap=true>
                                <!-- Text slides with image -->
                                <b-carousel-slide img-src="https://picsum.photos/480/480/?image=52"></b-carousel-slide>
                                <b-carousel-slide img-src="https://picsum.photos/480/480/?image=53"></b-carousel-slide>
                                <b-carousel-slide img-src="https://picsum.photos/480/480/?image=54"></b-carousel-slide>
    
                            </b-carousel>
                        </div>
                        <b-card-text style="text-align:left">
                            <b-row class="userInfo">
                                <b-col>이메일</b-col>
                                <b-col>test@test</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>닉네임</b-col>
                                <b-col>test</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>나이</b-col>
                                <b-col>25</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>재가입</b-col>
                                <b-col>ㅂㅈㄷㅂㅈㄷ</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>프로필 등급</b-col>
                                <b-col>ㅁㄴㅇㅁㄴㅇ</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>누적 결제</b-col>
                                <b-col>6</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>누적 출석</b-col>
                                <b-col>6</b-col>
                            </b-row>
                            <b-row class="userInfo">
                                <b-col>최근 결제 날짜</b-col>
                                <b-col>6</b-col>
                            </b-row>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col cols="1">
                </b-col>
            </b-row>
    
            <b-row>
                <b-col>
                    <b-button-group>
                        <b-button size="lg" class="resultBtn" pill>A</b-button>
                        <b-button size="lg" class="resultBtn" pill>B</b-button>
                        <b-button size="lg" class="resultBtn" pill>C</b-button>
                        <b-button size="lg" class="resultBtn" pill>D</b-button>
                        <b-button size="sm" class="resultBtn" pill>Black</b-button>
                    </b-button-group>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import NavComponent from './NavComponent.vue'

export default {
    name: 'FemaleRank',
    components: {
        NavComponent
    },
    data() {
        return {
            slide: 0,
            sliding: null
        }
    },
    methods: {
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        }
    }
}
</script>

<style scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.resultBtn {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
}

.userInfo {
    border-bottom: groove;
    border-width: 1px;
}
</style>
