<template>
  <div id="app">
    <NavComponent />
    <div class="content-container">

        <div class="content-container">
          <b-row>
            <b-col cols="2"> </b-col>
            <b-col>
              <p v-if="data == ''">
                <b-spinner
                  variant="primary"
                  style="width: 3rem; height: 3rem"
                  label="Large Spinner"
                ></b-spinner>
              </p>
              <p v-if="data == null">
                {{ contentString.noList[loginCountry] }} <br />{{
                  contentString.refresh[loginCountry]
                }}
                <br />{{ countDown }}
              </p>
              <div v-if="data">
                <p>totalCount: {{ totalCount }}</p>
              </div>
              <p
                size="sm"
                style="
                  margin-left: 15px;
                  margin-right: 15px;
                  margin-bottom: 5px;
                "
              >
                <b-badge
                  style="border: 1px solid; height: 30px;"
                  variant="outline-secondary"
                  ><span style="vertical-align: -webkit-baseline-middle"
                    >nickname</span
                  ></b-badge
                >
                <b-badge
                  style="border: 1px solid; margin-left: -1px; height: 30px;"
                  variant="outline-secondary"
                  ><span style="vertical-align: -webkit-baseline-middle; font-size: 20px;">{{
                    user.nickName
                  }}</span></b-badge
                >
                <b-badge
                  style="border: 1px solid; height: 30px"
                  variant="outline-secondary"
                  ><span style="vertical-align: -webkit-baseline-middle"
                    >요청 시간</span
                  ></b-badge
                >
                <b-badge
                  style="border: 1px solid; margin-left: -1px; height: 30px"
                  variant="outline-secondary"
                  ><span style="vertical-align: -webkit-baseline-middle">{{
                    request_at
                  }}</span></b-badge
                >
              </p>
              <b-button-group size="sm" style="margin-top: 7px">
                <b-button disabled variant="outline-dark"
                  ><strong>country</strong></b-button
                >
                <b-button disabled variant="outline-dark">{{
                  user.country
                }}</b-button>
                <b-button disabled variant="outline-dark"
                  ><strong>age</strong></b-button
                >
                <b-button disabled variant="outline-dark">{{
                  user.age
                }}</b-button>
                <b-button disabled variant="outline-dark"
                  ><strong>sex</strong></b-button
                >
                <b-button disabled variant="outline-dark">{{
                  user.sex
                }}</b-button>
                <b-button disabled variant="outline-dark"
                  ><strong>rank</strong></b-button
                >
                <b-button disabled variant="outline-dark">{{
                  user.rank
                }}</b-button>
                </b-button-group>
                <br />
                <b-button-group size="sm" style="margin-top: 7px">
                <b-button disabled variant="outline-dark"
                  ><strong>approval</strong></b-button
                >
                <b-button disabled variant="outline-dark">{{
                  user.approval
                }}</b-button>
                <b-button disabled variant="outline-dark"
                  ><strong>inapproval_type</strong></b-button
                >
                <b-button disabled variant="outline-dark">{{
                  user.inapproval_type
                }}</b-button>
              </b-button-group>
              <br />
              <b-button-group
                size="sm"
                style="margin-left: 15px; margin-right: 15px; margin-top: 7px"
              >
                <b-button disabled variant="outline-dark"
                  ><strong>누적 다이아 수</strong></b-button
                >
                <b-button disabled variant="outline-dark">{{
                  user.purchase_accumulate
                }}</b-button>
                <b-button disabled variant="outline-dark"
                  ><strong>subscription</strong></b-button
                >
                <b-button disabled variant="outline-dark">{{
                  user.subscription_status | moment("YYYY-MM-DD HH:mm:ss")
                }}</b-button>
              </b-button-group>
            </b-col>
            <b-col cols="2"> </b-col>
          </b-row>
        </div>

        <div class="image-list-container">
            <div id="card" v-for="(image, index) in imageList" v-bind:key="index" >
            <b-card-group v-bind:class="{'diff-image-border': image.image !== image.requestImage}" class="before-after-container">
              <div class="before">
                <b-badge pill variant="danger"> before </b-badge>
                <b-card
                  no-body
                  :img-src="
                    image.image == ''
                      ? require('@/assets/noImage.jpg')
                      : image.image
                  "
                  img-bottom
                  style="
                    width: auto;
                    height: auto;
                    margin-bottom: 5px;
                    max-width: 250px;
                  "
                  :v-model="selected[index]"
                >
                </b-card>
              </div>  
              
              <div class="after">
                <b-badge pill variant="success"> after </b-badge>
                <b-card
                  no-body
                  :img-src="image.requestImage"
                  img-bottom
                  style="
                    width: auto;
                    height: auto;
                    margin-bottom: 5px;
                    max-width: 250px;
                  "
                >
                </b-card>
              </div>
            </b-card-group>
        </div>
     
        </div>
        <p style="height: 55px; display: block"></p>
        <div>
          <b-navbar toggleable="lg" variant="light" fixed="bottom">
            <b-row style="margin: auto">
              <b-col>
                <b-button-group>
                  <b-button
                    variant="outline-success"
                    @click="updateApproval('A')"
                    class="resultBtn"
                    pill
                    >A</b-button
                  >
                  <b-button
                    variant="outline-success"
                    @click="updateApproval('B')"
                    class="resultBtn"
                    pill
                    >B</b-button
                  >
                  <b-button
                    variant="outline-success"
                    @click="updateApproval('C')"
                    class="resultBtn"
                    pill
                    >C</b-button
                  >
                  <b-button
                    variant="outline-warning"
                    @click="updateApproval('△')"
                    class="resultBtn"
                    pill
                    >△</b-button
                  >
                  <b-button
                    variant="outline-danger"
                    @click="blackTypeModalShow = !blackTypeModalShow"
                    class="resultBtn"
                    pill
                    >X</b-button
                  >

                  <b-modal
                    v-model="blackTypeModalShow"
                    hide-footer
                    hide-header
                    style="text-align: center"
                  >
                    <h5 style="text-align: center">
                      {{ contentString.rejectReason[loginCountry] }}
                    </h5>
                    <b-form-group>
                      <b-form-checkbox-group
                        size="sm"
                        v-model="selectedOption"
                        :options="options"
                        button-variant="outline-primary"
                        stacked
                        buttons
                        style="
                          width: 100%;
                          word-break: keep-all;
                          word-wrap: normal;
                        "
                      ></b-form-checkbox-group>
                    </b-form-group>
                    <div class="d-block text-center lg">
                      <b-button
                        variant="success"
                        pill
                        @click="
                          (blackTypeModalShow = !blackTypeModalShow),
                            updateApproval('X')
                        "
                        @keyup.enter="
                          (blackTypeModalShow = !blackTypeModalShow),
                            updateApprovalBlackType()
                        "
                        style="margin-right: 15px"
                      >
                        {{ contentString.accept[loginCountry] }}
                      </b-button>
                      <b-button
                        variant="danger"
                        pill
                        @click="
                          (blackTypeModalShow = !blackTypeModalShow),
                            (selectedOption = [])
                        "
                        @keyup.esc="
                          (blackTypeModalShow = !blackTypeModalShow),
                            (selectedOption = [])
                        "
                        style="margin-left: 15px"
                      >
                        {{ contentString.reject[loginCountry] }}
                      </b-button>
                    </div>
                  </b-modal>

                  <b-button
                    variant="outline-info"
                    class="changeBtn"
                    @click="
                      changeProfileImageModalShow = !changeProfileImageModalShow
                    "
                    pill
                    >↕</b-button
                  >
                  <b-modal
                    v-model="changeProfileImageModalShow"
                    hide-footer
                    hide-header
                  >
                    <b-row>
                      <b-col cols="3"> </b-col>
                      <b-col cols="5" style="margin: auto">
                        <draggable
                          :list="list"
                          :disabled="!enabled"
                          ghost-class="ghost"
                          @start="dragging = true"
                          @end="dragging = false"
                        >
                          <p
                            style="padding: unset"
                            v-for="element in list"
                            :key="element.src"
                          >
                            <b-img
                              style="width: 100%"
                              :src="element.src"
                            ></b-img>
                          </p>
                        </draggable>
                      </b-col>
                      <b-col cols="3">
                        <!--
                                                <p v-for="element in list" :key="element.id" style="margin-bottom:165%">
                                                    <b-button pill variant="outline-dark" @click="deleteImage(element.id)">
                                                        X
                                                    </b-button>
                                                    <br>
                                                </p>
                                                -->
                      </b-col>
                    </b-row>
                    <div
                      class="d-block text-center lg"
                      style="margin-top: 10px"
                    >
                      <b-button
                        variant="success"
                        pill
                        @click="
                          changeProfileImageModalShow =
                            !changeProfileImageModalShow
                        "
                        @keyup.enter="
                          changeProfileImageModalShow =
                            !changeProfileImageModalShow
                        "
                        style="margin-right: 15px"
                      >
                        {{ contentString.accept[loginCountry] }}
                      </b-button>
                      <b-button
                        variant="info"
                        pill
                        style="margin-right: 15px; margin-left: 15px"
                        @click="recoverOld()"
                      >
                        {{ contentString.restore[loginCountry] }}
                      </b-button>
                      <b-button
                        variant="danger"
                        pill
                        @click="
                          changeProfileImageModalShow =
                            !changeProfileImageModalShow
                        "
                        @keyup.esc="
                          changeProfileImageModalShow =
                            !changeProfileImageModalShow
                        "
                        style="margin-left: 15px"
                      >
                        {{ contentString.reject[loginCountry] }}
                      </b-button>
                    </div>
                  </b-modal>
                </b-button-group>
              </b-col>
            </b-row>
          </b-navbar>
        </div>
    </div>
  </div>
</template>

<script>
import NavComponent from "./NavComponent.vue";
import ImageCheckJson from "../assets/string/imageCheck.json";
import draggable from "vuedraggable";
export default {
  name: "ImageCheck",
  components: {
    NavComponent,
    draggable,
  },
  watch: {
    data: function () {
      if (this.data == null) {
        this.refreshtimer = setInterval(() => {
          this.$router.go(0);
          clearTimeout(this.refreshtimer);
        }, 5000);
        this.refreshCountDown();
      }
    },
    selectedOption: function () {
      if (this.selectedOption.length > 1) this.selectedOption.splice(0, 1);
    },
  },
  data() {
    return {
      refreshtimer: null,
      user: {
        id: "",
        nickName: "",
        age: "",
        rank: "",
        subscription_status: "",
        sex: "",
        approval: "",
        purchase_accumulate: 0,
        inapproval_type: "",
        country: "",
      },
      imageList: null,
      data: "",
      selected: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      },
      modalShow: false,
      sendType: "",
      loginCountry: this.$session.get("country"),
      contentString: ImageCheckJson.contentString,
      countDown: 5,
      blackTypeModalShow: false,
      blackApprovalModalShow: false,
      changeProfileImageModalShow: false,
      selectedOption: [], // Must be an array reference!
      options: [],
      list: [
        /*
                {src : '', id: 0, imageId: 0}
                */
      ],
      oldImageList: [
        /*
                {src : '', id: 0, imageId: 0}
                */
      ],
      dragging: false,
      enabled: true,
    };
  },
  beforeDestroy() {
    clearTimeout(this.refreshtimer);
  },
  beforeCreate() {
    this.$http
      .get(
        `${process.env.VUE_APP_API}/management/account/images/wait_approvals/`,
        {
          headers: { Authorization: this.$session.get("token") },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length != 0) {
            this.data = res.data;
            console.log(this.data);
            this.imageLoad();
          } else this.data = null;
        }
      })
      .catch((err) => {
        this.data = null;
        alert(err);
      });
  },
  beforeMount() {
    let country = "description_ko";
    if (this.loginCountry == "JP") {
      country = "description_ja";
    } else if (this.loginCountry == "EN") {
      country = "description_en";
    } else if (this.loginCountry == "MENA") {
      country = "description_en";
    } 
    // TODO
    else if (this.loginCountry == "CN") {
      country = "description_en";
    }
    this.$http
      .get(`${process.env.VUE_APP_API}/account/v2/inapproval-types/`, {
        headers: { Authorization: this.$session.get("token") },
      })
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((data) => {
            this.options.push({
              value: data.type,
              text: data[country],
            });
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  },
  methods: {
    formatDateTimeString(inputDateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
        timeZone: "Asia/Seoul",
      };
      const now = Date.now();
      const date = new Date(inputDateString);
      const timeDiffHours = Math.floor((now - date) / (1000 * 60 * 60));

      // Format time difference string
      const timeDiffStr = `${timeDiffHours}시간 전`;
      const formattedDate = date.toLocaleDateString("ko-KR", options);
      // Concatenate formatted date and time difference strings
      const result = `${formattedDate} (${timeDiffStr})`;
      return result;
    },
    imageLoad() {
      this.totalCount = this.data.length;
      const user = this.data[0];
      this.user.id = user.id;
      this.user.nickName = user.nickname;
      this.user.age = user.age;
      this.user.rank = user.profileLevel;
      this.user.purchase_accumulate = user.purchase_accumulate;
      this.imageList = user.profileImages.map((img) => {
        return { ...img, updated_at: new Date(img.updated_at) };
      });
      this.user.sex = user.sex;
      this.user.approval = user.approval;
      this.user.inapproval_type = this.getInapprovalType(user.inapproval_type);
      this.user.country = user.country;

      this.request_at = this.formatDateTimeString(
        this.imageList.sort(
          (imgA, imgB) => imgB.updated_at - imgA.updated_at
        )[0].updated_at
      );

      if (user.subscription_status != null) {
        this.user.subscription_status = user.subscription_status.expired_at;
      } else {
        this.user.subscription_status = "null";
      }

      this.updateImageList();
    },
    updateApproval(rank) {
      console.log(this.checkOptions(rank));
      this.$http
        .post(
          `${process.env.VUE_APP_API}/management/account/images/wait_approvals/`,
          this.checkOptions(rank),
          {
            headers: { Authorization: this.$session.get("token") },
          }
        )
        .then((res) => {
          if (res.status === 200 && this.data != null) {
            this.$toast.success(
              this.contentString.successMsg[this.loginCountry],
              {
                position: "top",
                duration: 1200,
              }
            );
          } else if (this.data == null) {
            this.$toast.warning(
              this.contentString.noListMsg[this.loginCountry],
              {
                position: "top",
                duration: 1200,
              }
            );
          }
          // update, clean lists
          if (this.data.length > 1) {
            this.data.splice(0, 1);
            this.imageLoad();
            this.resetCheckbox();
          } else {
            this.data.splice(0, 1);
            this.data = null;
            this.wip();
          }
        })
        .catch((err) => {
          this.$toast.error(err, {
            position: "top",
            duration: 1200,
          });
        });
    },
    wip() {
      // reset list
      this.$router.go(0);
    },
    updateImageList() {
      this.oldImageList = [];
      for (let i = 0; i < this.data[0].profileImages.length; i++) {
        this.oldImageList.push({
          approval: this.imageList[i].approval,
          image: this.imageList[i].image,
          src: this.imageList[i].requestImage,
          id: i,
          imageId: this.imageList[i].id,
        });
      }
      this.recoverOld();
    },
    deleteImage(index) {
      this.list = this.list.filter((element) => element.id != index);
    },
    recoverOld() {
      this.list = [];
      this.list = this.oldImageList.slice(0, this.oldImageList.length);
    },
    checkChangingImages() {
      console.log(this.list, this.oldImageList);
      if (this.list.length != this.oldImageList.length) {
        return false;
      }
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id != this.oldImageList[i].id) {
          return false;
        }
      }
      return true;
    },
    changeImageSrc() {
      for (let i = 0; i < this.list.length; i++) {
        this.imageList[i].requestImage = this.list[i].src;
        this.imageList[i].approval = this.list[i].approval;
        this.imageList[i].image = this.list[i].image;
      }
      this.imageList.splice(this.list.length, this.imageList.length);
    },
    checkOptions(rank) {
      let options = {};
      options.id = this.user.id;
      if (!this.checkChangingImages()) {
        this.changeImageSrc();
        options.profileImages = this.imageList;
      }
      if (rank == "X") {
        options.approvalStatus = "DISAPPROVED";
        options.disapprovalType = this.selectedOption[0];
      } else if (rank == "△") {
        options.approvalStatus = "UNIDENTIFIED_APPROVED";
      } else {
        options.approvalStatus = "APPROVED";
        options.profileLevel = rank;
      }
      return options;
    },
    resetCheckbox() {
      // set checkbox default value
      this.selected["0"] = false;
      this.selected["1"] = false;
      this.selected["2"] = false;
      this.selected["3"] = false;
      this.selected["4"] = false;
      this.selected["5"] = false;
      this.selectedOption = [];
    },
    refreshCountDown() {
      setTimeout(() => {
        this.countDown--;
        setTimeout(() => {
          this.countDown--;
          setTimeout(() => {
            this.countDown--;
            setTimeout(() => {
              this.countDown--;
            }, 1000);
          }, 1000);
        }, 1000);
      }, 1000);
    },
    getInapprovalType(inapproval_type) {
      if (inapproval_type == null) {
        return "null";
      } else {
        let discription = "description_ko";
        if (this.loginCountry == "JP") {
          discription = "description_ja";
        } else if (this.loginCountry == "EN") {
          discription = "description_en";
        } else {
          discription = "description_ko";
        }
        return inapproval_type[discription];
      }
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.diff-image-border{
  border:3px solid orangered;
}
.content-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#card {
  border: ridge;
}





.image-list-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

@media (max-width: 576px) {
    .image-list-container{
        display: flex;
        flex-direction: column;
    }
} 

.before-after-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 576px) {
    .before-after-container{
        display: flex;
        flex-direction: row;
    }
} 



.resultBtn {
  margin: 5px 10px 0px 10px;
  word-break: keep-all;
  word-wrap: normal;
}
</style>
