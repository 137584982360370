<template>
  <div id="app" style="width: 100%; height: 100%; padding-top: 0px">
    <NavComponent />
    <b-container id="header" style="margin-top: 0">
      <div style="width: 40%; margin-left: 30%">
        <b-form-input
          v-model="email"
          placeholder="email"
          v-on:keyup.enter="getActionLog()"
          required
        ></b-form-input>
        <div
          style="display: flex; flex-direction: column; justify-content: center"
        >
          <div
            style="display: flex; flex-direction: row; margin: 8px; gap: 4px"
          >
            <div style="width: 210px; display: inline-block">
              start date
              <b-form-input
                v-model="startDate"
                v-on:keyup.enter="getActionLog()"
                placeholder="start date (YYYY-MM-DD)"
              ></b-form-input>
            </div>

            <div style="width: 210px; display: inline-block">
              end date
              <b-form-input
                v-model="endDate"
                v-on:keyup.enter="getActionLog()"
                placeholder="end date (YYYY-MM-DD)"
              ></b-form-input>
            </div>
          </div>
          default : 3 days ago from current date<br />
          <div style="margin-top: 12px">
            <b-button
              variant="info"
              @click="getActionLog()"
              style="width: 120px; font-size: 20px; font-weight: 500"
              >search</b-button
            >
          </div>
        </div>
      </div>
    </b-container>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100%;
      "
    >
      <span v-show="loading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </span>
      <div v-show="!loading" style="width: 100%">
        <b-table
          sticky-header="700px"
          style="font-size: 13px; margin: 10px; width: 100%"
          hover
          :items="logDataList"
          :fields="dataFields"
        >
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import NavComponent from "./NavComponent.vue";

export default {
  name: "calling",
  components: {
    NavComponent,
  },
  data() {
    return {
      email: "",
      startDate: this.$moment().subtract(3, "days").format("YYYY-MM-DD"),
      endDate: this.$moment().format("YYYY-MM-DD"),
      loading: false,
      logDataList: [],
      dataFields: [
        "inven_change_id",
        "email",
        "log_type",
        "log_name",
        "item_type",
        "type",
        "count",
        "after_item",
        "ticket",
        "after_ticket",
        "cash",
        "after_cash",
        "days",
        "after_expired_at",
        "created_at",
      ],
    };
  },

  methods: {
    getActionLog() {
      this.loading = true;
      if (this.startDate == "" && this.endDate == "") {
        this.$http
          .get(process.env.VUE_APP_API + "/management/log/item_action_logs/", {
            headers: { Authorization: this.$session.get("token") },
            params: {
              email: this.email,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              this.logDataList = res.data.data;
              this.logDataList.sort((a, b) =>
                a.created_at > b.created_at ? -1 : 1
              );
              this.logDataList.forEach((logData) => {
                if (
                  logData.after_expired_at == undefined ||
                  logData.after_expired_at == null
                ) {
                  logData.after_expired_at = "-";
                } else {
                  logData.after_expired_at = this.$moment(
                    logData.after_expired_at
                  ).format("YY/MM/DD HH:mm:ss");
                }
                logData.created_at = this.$moment(logData.created_at).format(
                  "YY/MM/DD HH:mm:ss"
                );
              });
            }
          })
          .catch((err) => {
            alert("Error" + err);
          });
      } else {
        this.$http
          .get(process.env.VUE_APP_API + "/management/log/item_action_logs/", {
            headers: { Authorization: this.$session.get("token") },
            params: {
              email: this.email,
              created_at__gte: this.startDate,
              created_at__lte: this.endDate,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              this.logDataList = res.data.data;
              this.logDataList.sort((a, b) =>
                a.created_at > b.created_at ? -1 : 1
              );
              this.logDataList.forEach((logData) => {
                if (
                  logData.after_expired_at == undefined ||
                  logData.after_expired_at == null
                ) {
                  logData.after_expired_at = "-";
                } else {
                  logData.after_expired_at = this.$moment(
                    logData.after_expired_at
                  ).format("YY/MM/DD HH:mm:ss");
                }
                logData.created_at = this.$moment(logData.created_at).format(
                  "YY/MM/DD HH:mm:ss"
                );
              });
              this.logDataList.forEach((logData) => {
                if (
                  logData.log_type == "action_log" ||
                  logData.log_type == "quest_reward_log"
                ) {
                  logData._rowVariant = "secondary";
                } else if (logData.log_type == "구독") {
                  logData._rowVariant = "success";
                } else if (
                  logData.log_type == "지급" ||
                  logData.log_type == "item_supplement_log" ||
                  logData.log_type == "퀘스트 보상"
                ) {
                  logData._rowVariant = "warning";
                } else if (logData.log_type == "인앱 구매") {
                  logData._rowVariant = "danger";
                } else if (logData.log_type == "관리자 재화 지급/회수 요청") {
                  logData._rowVariant = "info";
                } else if (logData.log_type == "기능") {
                  if (logData.log_name.includes("롤백")) {
                    logData._rowVariant = "ark";
                  } else {
                    logData._rowVariant = "primary";
                  }
                }
              });
            }
          })
          .catch((err) => {
            alert("Error" + err);
          });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container {
  text-align: center;
}
</style>
