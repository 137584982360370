<!-- 피드 상세 페이지 -->
<template>
  <div id="app">
    <NavComponent />
    <b-container v-if="feedData != null">
      <b-row>
        <b-col class="nameFields" cols="2">
          user_email:
          <!-- 고정 -->
        </b-col>
        <b-col class="feedData" cols="8">
          <b-button @click="openAdminUserPage(feedData.user.email)">
            {{ feedData.user.email }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="nameFields" cols="2">
          content_type:
          <!-- 고정 -->
        </b-col>
        <b-col class="feedData" cols="8">
          {{ feedData.feedpost.content.type }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="nameFields" cols="2">
          created_at:
          <!-- 고정 -->
        </b-col>
        <b-col class="feedData" cols="8">
          {{ getTimeStamp(feedData.feedpost.created_at) }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="nameFields" cols="2">
          content:
          <!-- 수정가능 -->
        </b-col>
        <b-col class="feedData" cols="8">
          <b-form-textarea
            id="textarea"
            v-model="changedFeedContent"
            :placeholder="feedData.feedpost.content.text"
            rows="3"
            max-rows="6"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="nameFields" cols="2">
          comments:
          <!-- 고정 -->
        </b-col>
        <b-col class="feedData" cols="8">
          <b-button
            @click="
              getComments(feedData.feedpost.feedpost_id),
                $bvModal.show('commentModal')
            "
          >
            {{ feedData.feedpost.total_count_comments }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="nameFields" cols="2">
          total_likes:
          <!-- 고정 -->
        </b-col>
        <b-col class="feedData" cols="8">
          {{ feedData.feedpost.total_count_likes }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="nameFields" cols="2">
          manage_likes:
          <!-- 수정 가능 -->
        </b-col>
        <b-col class="feedData" cols="8">
          <b-form-input
            id="input-small"
            size="sm"
            :placeholder="String(feedData.feedpost.total_count_manage_likes)"
            v-model="changedManageLikesCount"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="nameFields" cols="2">
          reports:
          <!-- 고정 -->
        </b-col>
        <b-col class="feedData" cols="8">
          {{ feedData.feedpost.total_count_reports }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="nameFields" cols="2">
          hide:
          <!-- 수정 가능 // enum-->
        </b-col>
        <b-dropdown id="dropdown-1" :text="currentHideType" class="m-md-2">
          <b-dropdown-item @click="changeHideType('none')"
            >none</b-dropdown-item
          >
          <b-dropdown-item @click="changeHideType('전체숨김')"
            >전체숨김</b-dropdown-item
          >
          <b-dropdown-item @click="changeHideType('신고누적숨김')"
            >신고누적숨김</b-dropdown-item
          >
          <b-dropdown-item @click="changeHideType('작외숨')"
            >작외숨</b-dropdown-item
          >
        </b-dropdown>
      </b-row>

      <b-row>
        <b-col class="isDeleteFields" cols="2">
          isDelete:
          <!-- 수정 가능 // enum-->
        </b-col>
        <b-dropdown id="dropdown-5" :text="currentDeleteType" class="m-md-2">
          <b-dropdown-item @click="changeIsDelete('true')"
            >true</b-dropdown-item
          >
          <b-dropdown-item @click="changeIsDelete('false')"
            >false</b-dropdown-item
          >
        </b-dropdown>
      </b-row>

      <b-row>
        <b-col class="nameFields" cols="2">
          content_url:
          <!-- 삭제가능 -->
        </b-col>
        <b-col class="feedData" cols="8">
          <div v-if="feedData.feedpost.content.type == 'GIF'">
            <b-img :src="feedData.feedpost.content.url[0]['url']" />
            <br />
          </div>
          <div v-else-if="feedData.feedpost.content.type == 'AUDIO'">
            <audio
              controls
              :src="feedData.feedpost.content.url[0]['url']"
            ></audio>
            <br />
            {{ feedData.feedpost.content.url[0] }}
          </div>
          <div v-else-if="feedData.feedpost.content.type == 'IMAGE'">
            <div
              v-for="(imageUrl, index) in feedData.feedpost.content.url"
              v-bind:key="index"
            >
              <b-img :src="imageUrl.url" />
              {{ imageUrl.url }}
            </div>
          </div>
          <div v-else-if="feedData.feedpost.content.type == 'CALL_ROOM'">
            <b-button @click="openCallRoomPage(feedData.feedpost.content.url[0].id)">
              {{ feedData.feedpost.content.url[0].id }}
            </b-button>
          </div>

        
        </b-col>
      </b-row>

      <!-- 댓글 가져오기 모달 -->
      <b-modal id="commentModal" hide-footer size="xl">
        <template #modal-title>
          {{ feedOriginId }}
        </template>
        <b-table hover :items="comments" small>

          <template #cell(id)="row">
            <b-button
              size="sm"
              class="mr-2"
              @click="openFeedComment(row.item.uuid, row.item.id, row.item)"
            >
              {{ row.item.id }}
            </b-button>
          </template>
          <template #cell(user)="row">
            <b-button
              size="sm"
              class="mr-2"
              @click="openAdminUserPage(row.item.user)"
            >
              {{ row.item.user }}
            </b-button>
          </template>

        </b-table>
        <b-button class="mt-3" block @click="$bvModal.hide('commentModal')"
          >닫기</b-button
        >
      </b-modal>
    </b-container>
    <b-container fluid v-if="feedData != null">
      <b-row>
        <div class="profileExchangeHeader">
          <h1 style="margin: 0;">프로필 교환 신청</h1>
        </div>
        <b-table
          hover 
          :items="profileExchanges" 
          :fields="profileExchangesTableFields"
          small
        >
          <template #cell(id)="row">
            <b-link
              class="clickableId"
              :href="row.item.url"
              target="_blank"
            >
              {{row.item.id}}
            </b-link>
          </template>

          <template #cell(targetUser_responded_at)="row">
            {{row.item.targetUser_responded_at ? formatDateTime(row.item.targetUser_responded_at) : "-"}}
          </template>

          <template #cell(user_requested_at)="row">
            {{row.item.user_requested_at ? formatDateTime(row.item.user_requested_at) : "-"}}
          </template>

          <template #cell(comment)="row">
            {{row.item.comment ? row.item.comment : "-"}}
          </template>
        </b-table>
      </b-row>

      <b-button style="margin-right: 10px" @click="updateFeedContent()"
        >UPDATE</b-button
      >
      <b-button style="margin-left: 10px" @click="deleteFeedContent()"
        >DELETE</b-button
      >
    </b-container>
  </div>
</template>

<script>
import NavComponent from "./NavComponent.vue";

export default {
  name: "FeedContent",
  components: {
    NavComponent,
  },
  data() {
    return {
      feedData: null,
      currentHideType: null,
      currentDeleteType: null,
      changedManageLikesCount: null,
      changedFeedContent: null,
      comments: [],
      loginCountry: this.$session.get("country"),
      apiBaseUrl: "",
      postId: null,
      profileExchanges: [],
      profileExchangesTableFields: [
        { key: 'id', label: 'ID' },
        { key: 'user.email', label: '발신자' },
        { key: 'user_status', label: '발신자 교환 상태' },
        { key: 'user_requested_at', label: '신청 일자' },
        { key: 'targetUser.email', label: '수신자' },
        { key: 'targetUser_status', label: '수신자 교환 상태' },
        { key: 'targetUser_responded_at', label: '응답 일자' },
        { key: 'comment', label: '신청 피드 댓글' }
      ],
    };
  },
  beforeMount() {
    this.apiBaseUrl = process.env.VUE_APP_FEED_SERVICE_URL;

    if (!this.$route.params.feed) {
      this.$http
        .get(
          `${this.apiBaseUrl}/management/feedposts/${this.$route.params.id}`,
          {
            headers: {
              Authorization: this.$session.get("token"),
              uuid: this.$session.get("uuid"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data);
            this.feedData = res.data.data;
            // this.currentHideType = res.data.data.feedpost.hide;
            this.profileExchanges = res.data.data.profile_exchanges;

            if(res.data.data.feedpost.hide == "NONE") {
              this.currentHideType = "none";
            } else if(res.data.data.feedpost.hide == "CS") {
              this.currentHideType = "전체숨김"
            } else if(res.data.data.feedpost.hide == "REPORT") {
              this.currentHideType = "신고누적숨김"
            } else if(res.data.data.feedpost.hide == "OWN") {
              this.currentHideType = "작외숨"
            }

            // if (it.feedpost.content.text.length >= 10) {
            //   it.feedpost.content.text = it.feedpost.content.text.substring(0, 10) + "..."
            // }

            this.changedManageLikesCount =
              res.data.data.feedpost.total_count_manage_likes;
            this.changedFeedContent = res.data.data.feedpost.content.text;
            this.postId = res.data.data.feedpost.feedpost_id;
            if(res.data.data.feedpost.is_delete) {
              this.currentDeleteType = 'true';
            } else {
              this.currentDeleteType = 'false';
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      this.feedData = this.$route.params.feed;
      this.currentHideType = this.feedData.hide;
    }
  },
  methods: {
    getTimeStamp(created_at) {
      return this.$moment(created_at).format("YY/MM/DD_HH:mm");
    },
    openAdminUserPage(email) {
      window.open(
        `${process.env.VUE_APP_DJANGO_URL}/ko/yp/manage/service/admin/account/user/?q=${email}`
      );
    },
    openCallRoomPage(callRoom) {
      window.open(
        `${process.env.VUE_APP_DJANGO_URL}/ko/yp/manage/service/admin/voice/roomcallwaitingroom/${callRoom}/change/`
      );
    },
    changeHideType(type) {
      this.currentHideType = type
    },
    changeIsDelete(del) {
      this.currentDeleteType = del;
    },
    updateFeedContent() {
      console.log(
        this.changedFeedContent,
        this.changedManageLikesCount,
        this.currentHideType,
        this.currentDeleteType
      );

      let hideType = ''

      if(this.currentHideType == "none") {
        hideType = "NONE";
      } else if(this.currentHideType == "전체숨김") {
        hideType = "CS"
      } else if(this.currentHideType == "신고누적숨김") {
        hideType = "REPORT"
      } else if(this.currentHideType == "작외숨") {
        hideType = "OWN"
      }

      this.$http
        .put(
          `${this.apiBaseUrl}/management/feedposts/${this.feedData.feedpost.feedpost_id}`,
          {
            text: this.changedFeedContent,
            totalCountManageLikes: this.changedManageLikesCount,
            hide: hideType,
            isDelete: this.currentDeleteType == 'true' ? true : false
          },
          {
            headers: {
              Authorization: this.$session.get("token"),
              uuid: this.$session.get("uuid"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            alert("success");            
          }
        })
        .catch((err) => {
          alert(err);
        });
        this.$router.go();
    },
    deleteFeedContent() {
      this.$http
        .delete(`${this.apiBaseUrl}/management/feedposts/${this.postId}`, {
          headers: {
            Authorization: this.$session.get("token"),
            uuid: this.$session.get("uuid"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            alert("success");
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    getComments(activityId) {
      console.log(activityId);
      this.$http
        .get(
          this.apiBaseUrl + `/management/feedposts/${activityId}/comments/`,
          {
            headers: {
              Authorization: this.$session.get("token"),
              uuid: this.$session.get("uuid"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.feedOriginId = activityId;
            console.log(res.data);
            this.filterComments(res.data.data.comments);
            console.log("AAA: ",res.data.data.comments);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    filterComments(comments) {
      this.comments = [];
      comments.forEach((comment) => {
        let bigCommentList = [];
        if (comment.children.length > 0) {
          comment.children.forEach((bigComment) => {
            bigCommentList = [
              {
                comment: "┗",
                parent_id: comment.comment.comment_id,
                id: bigComment.comment.comment_id,
                user: bigComment.user.email,
                content_type: bigComment.comment.content.type,
                content: bigComment.comment.content.text,
                likesCount: bigComment.comment.total_count_likes,
                reportsCount: bigComment.comment.total_count_reports,
                hide: bigComment.comment.hide,
                is_delete: bigComment.comment.is_delete,
                //"uuid": bigComment.comment.user.uuid,
                created_at: this.$moment(bigComment.comment.created_at).format(
                  "YY/MM/DD_HH:mm"
                ),
                // "created_at": bigComment.created_at
              },
              ...bigCommentList,
            ];
          });
        }
        this.comments = [
          {
            comment: "origin",
            id: comment.comment.comment_id,
            user: comment.user.email,
            content_type: comment.comment.content.type,
            content: comment.comment.content.text,
            likesCount: comment.comment.total_count_likes,
            reportsCount: comment.comment.total_count_reports,
            hide: comment.comment.hide,
            is_delete: comment.comment.is_delete,
            //"uuid": comment.user.uuid,
            created_at: this.$moment(comment.comment.created_at).format(
              "YY/MM/DD_HH:mm"
            ),
            // "created_at": comment.created_at
          },
          ...bigCommentList,
          ...this.comments,
        ];
      });
    },
    openFeedComment(userId, commentId, item) {
      console.log(item)
      const routeData = this.$router.resolve({
        name: "feedComment",
        params: { user_id: item.user, comment_id: item.id },
      });
      window.open(routeData.href, "_blank");
    },
    formatDateTime(date) {
      if (!date) return;

      const newDate = new Date(date);

      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      let hours = newDate.getHours();
      let minutes = newDate.getMinutes();
      const ampm = hours >= 12 ? '오후' : '오전';

      hours = hours % 12;
      minutes = minutes < 10 ? `0${minutes}` : minutes;

      return `${year}년 ${month}월 ${day}일 ${hours}:${minutes} ${ampm} `
    }
  },
};
</script>

<style scoped>
.nameFields {
  font-weight: bold;
  text-align: right;
}

.feedData {
  text-align: left;
}

.profileExchangeHeader {
  width: 100%;
  padding: 13px 30px;
  background: #83ADC5;
  margin-top: 75px;
}

.profileExchangeHeader h1 {
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  color: #FFF;
  text-align: left;
}

.clickableId {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #6C8FA7;
}
</style>