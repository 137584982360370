<template>
    <div id="app">
        <NavComponent />
        <b-col cols="4">
            <p style="margin-top: 30px; margin-bottom:30px">
                <b-input-group prepend="user-email" class="mt-1">
                    <b-form-input v-model="userEmail"></b-form-input>
                    <b-input-group-append>
                        <b-button variant="info" @click="findBlockListByEmail()" @keyup.enter="findBlockListByEmail()">
                            Search
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </p>
        </b-col>
    </div>
</template>

<script>
import NavComponent from './NavComponent.vue'

export default {
    name: 'FeedBlock',
    components: {
        NavComponent
    },
    data() {
        return {
            userEmail: '',
            blockList: null
        }
    },
    beforeMount() {
    },
    methods:{
        findBlockListByEmail(){

        }
    }
}
</script>

<style scoped>
</style>