<template>
  <div id="app">
    <NavComponent />
    <b-col cols="4">
      <p style="margin-top: 30px; margin-bottom: 30px">
        <!-- <b-input-group prepend="activity_id" class="mt-1">
                    <b-form-input v-model="userID"></b-form-input>
                    <b-input-group-append>
                        <b-button variant="info" @click="findByActivityId()" @keyup.enter="findByActivityId()">
                            Search
                        </b-button>
                    </b-input-group-append>
                </b-input-group> -->
      </p>
    </b-col>
    <b-table hover :items="feedList" :fields="feedTableFields" small>
      <template #cell(feed.total_count_comments)="row">
        <b-button
          class="mr-2"
          size="sm"
          @click="
            getComments(row.item.feed.feedpost_id);
            $bvModal.show('commentModal');
          "
        >
          {{ row.item.feed.total_count_comments }}
        </b-button>
      </template>
      <template #cell(user.email)="row">
        <b-button
          class="mr-2"
          size="sm"
          @click="openAdminUserPage(row.item.user.email)"
        >
          {{ row.item.user.email }}
        </b-button>
      </template>
      <template #cell(feed.feedpost_id)="row">
        <b-button
          size="sm"
          class="mr-2"
          @click="openFeedContent(row.item.feed.feedpost_id, row.item)"
        >
          {{ row.item.feed.feedpost_id }}
        </b-button>
      </template>
      <template #cell(feed.created_at)="row">
        {{ getTimeStamp(row.item.feed.created_at) }}
      </template>
    </b-table>
    <b-button @click="loadMoreFeeds()"> MORE </b-button>
    <b-modal id="commentModal" hide-footer size="xl">
      <template #modal-title>
        {{ feedOriginId }}
      </template>
      <b-table
        hover
        :items="comments"
        :fields="commentTableFields"
        small
        fixed
        style="word-break: break-all"
      >
        <template #cell(id)="row">
          <b-button
            size="sm"
            class="mr-2"
            @click="openFeedComment(row.item.uuid, row.item.id)"
          >
            {{ row.item.id }}
          </b-button>
        </template>
        <template #cell(user)="row">
          <b-button
            size="sm"
            class="mr-2"
            @click="openAdminUserPage(row.item.user)"
          >
            {{ row.item.user }}
          </b-button>
        </template>
      </b-table>
      <b-button class="mt-3" block @click="$bvModal.hide('commentModal')"
        >닫기</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import NavComponent from "./NavComponent.vue";

export default {
  name: "Feed",
  components: {
    NavComponent,
  },
  data() {
    return {
      
      feedTableFields: [
        { key: "feed.feedpost_id", label: "feedpost_id" },
        { key: "user.email", label: "email" },
        { key: "feed.access", label: "access" },
        { key: "feed.content.text", label: "text" },
        { key: "feed.content.type", label: "content type" },
        { key: "feed.total_count_comments", label: "count_comments" },
        { key: "feed.total_count_likes", label: "count_likes" },
        { key: "feed.total_count_reports", label: "count_reports" },
        { key: "feed.hide", label: "hide" },
        { key: "feed.created_at", label: "created_at" },
      ],
      feedList: null,
      commentTableFields: [
        "comment",
        "id",
        "email",
        "content_type",
        "content",
        "likesCount",
        "reportsCount",
        "hide",
        "created_at",
      ],
      comments: [],
      feedOriginId: "",
      feedPage: 1,
      loginCountry: this.$session.get("country"),
      apiBaseUrl: "",
    };
  },
  beforeMount() {
    this.apiBaseUrl = process.env.VUE_APP_FEED_SERVICE_URL;

    this.$http
      .get(this.apiBaseUrl + "/management/feeds?page=0", {
        headers: {
          Authorization: this.$session.get("token")
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.feedList = res.data.data.feed_list;
          console.log(this.feedList);
        }
      })
      .catch((err) => {
        alert(err);
      });
  },
  methods: {
    getTimeStamp(created_at) {
      return this.$moment(created_at).format("YY/MM/DD_HH:mm");
    },
    openAdminUserPage(email) {
      window.open(
        `${process.env.VUE_APP_DJANGO_URL}/ko/yp/manage/service/admin/account/user/?q=${email}`
      );
    },
    loadMoreFeeds() {
      this.$http
        .get(this.apiBaseUrl + "/management/feeds?page=" + this.feedPage, {
          headers: {
            Authorization: this.$session.get("token"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.feedList = [...this.feedList, ...res.data.data.feed_list];
            console.log(this.feedList);
            this.feedPage += 1;
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    },
    getComments(activityId) {
      console.log(activityId);
      this.$http
        .get(
          this.apiBaseUrl + `/management/feedposts/${activityId}/comments/`,
          {
            headers: {
              Authorization: this.$session.get("token")
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.feedOriginId = activityId;
            console.log("A", res.data);
            this.filterComments(res.data.data.comments);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    filterComments(comments) {
      this.comments = [];

      comments.forEach((comment) => {
        let bigCommentList = [];
        if (comment.children.length > 0) {
          comment.children.forEach((bigComment) => {
            console.log(bigComment);
            bigCommentList = [
              {
                comment: "┗",
                parent_id: comment.comment.comment_id,
                id: bigComment.comment.comment_id,
                // "user": bigComment.comment.user.uuid,
                content_type: bigComment.comment.content.type,
                content: bigComment.comment.content.text,
                likesCount: bigComment.comment.total_count_likes,
                reportsCount: bigComment.comment.total_count_reports,
                hide: bigComment.comment.hide,
                email: bigComment.user.email,
                uuid: bigComment.user.uuid,
                created_at: this.$moment(bigComment.comment.created_at).format(
                  "YY/MM/DD_HH:mm"
                ),
                // "created_at": bigComment.created_at
              },
              ...bigCommentList,
            ];
          });
        }
        this.comments = [
          {
            comment: "origin",
            id: comment.comment.comment_id,
            uuid: comment.user.uuid,
            content_type: comment.comment.content.type,
            content: comment.comment.content.text,
            likesCount: comment.comment.total_count_likes,
            reportsCount: comment.comment.total_count_reports,
            hide: comment.comment.hide,
            email: comment.user.email,
            created_at: this.$moment(comment.comment.created_at).format(
              "YY/MM/DD_HH:mm"
            ),
            // "created_at": comment.created_at
          },
          ...bigCommentList,
          ...this.comments,
        ];

      });
    },
    openFeedContent(id, feed) {
      console.log(id, feed);
      const routeData = this.$router.resolve({
        name: "feedContent",
        params: { id: id, feed: feed },
      });
      window.open(routeData.href, "_blank");
    },
    openFeedComment(userId, commentId) {
      const routeData = this.$router.resolve({
        name: "feedComment",
        params: { user_id: userId, comment_id: commentId },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped>
</style>