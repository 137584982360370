<template>
    <div id="app">
        <NavComponent />
        {{$route.params.roomid}}
    </div>
</template>

<script>
import NavComponent from './NavComponent.vue'

export default {
    name: 'Room',
    components: {
        NavComponent
    },
    data() {
        return {}
    }
}
</script>

<style scoped>
</style>
