<template>
  <div id="app">
    <NavComponent />
    <b-col cols="4">
      <p style="margin-top: 30px; margin-bottom: 30px">
        <b-input-group prepend="email/nickname" class="mt-1">
          <b-form-input v-model="searchInput"></b-form-input>
          <b-input-group-append>
            <b-button
              variant="info"
              @click="findFollowListByEmail()"
              @keyup.enter="findFollowListByEmail()"
            >
              Search
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </p>
    </b-col>
    <b-row>
      <b-col cols="6">
        {{ followingList }}
      </b-col>
      <b-col cols="6">
        {{ followerList }}
      </b-col>
    </b-row>
    {{ followData }}
  </div>
</template>

<script>
import NavComponent from "./NavComponent.vue";

export default {
  name: "FeedFollow",
  components: {
    NavComponent,
  },
  data() {
    return {
      searchInput: "",
      followingList: null,
      followerList: null,
      searchUserUuid: "",
      followData: null,
    };
  },
  beforeMount() {},
  methods: {
    async findFollowListByEmail() {
      await this.$http
        .get(
          `${process.env.VUE_APP_FEED_SERVICE_URL}/management/users/followers-followings?email=${this.searchInput}`,
          {
            headers: {
              Authorization: this.$session.get("token")
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            //this.searchUserUuid = res.data.data.uuid;

            this.followingList = res.data.data.followList.followings.map((item) => {
              return item.email
            })

  
            this.followerList = res.data.data.followList.followers.map((item) => {
              return item.email
            })
            //this.followData = res.data
          }
        })
        .catch((err) => {
          alert(err);
        });

      // await this.$http.get(`${process.env.VUE_APP_FEED_API}${process.env.VUE_APP_GET_FOLLOWLIST}?uuid=${this.searchUserUuid}`)
      // .then((res) => {
      //     if (res.status === 200){
      //         this.followData = res
      //         this.followingList = res.data.followList.followings.results
      //         this.followerList = res.data.followList.followers.results
      //     }
      // })
      // .catch((err) => {
      //     alert(err)
      // })

      // console.log(this.followData)
    },
  },
};
</script>

<style scoped>
</style>