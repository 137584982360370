<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="info" fixed="top">
      <b-navbar-brand>
        <b-img
          @click="$router.push('/home')"
          :src="require('@/assets/yplabs_service_logo.jpg')"
          :width="30"
          rounded
        ></b-img>
        Conneting Manager
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template v-slot:button-content>
              Language({{ $session.get("country") }})
            </template>
            <b-dropdown-item @click="changeLanguage('KR')">KR</b-dropdown-item>
            <b-dropdown-item @click="changeLanguage('JP')">JP</b-dropdown-item>
            <b-dropdown-item @click="changeLanguage('EN')">EN</b-dropdown-item>
            <!-- TODO -->
            <b-dropdown-item @click="changeLanguage('EN')">CN</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown right>
            <template v-slot:button-content> Menu </template>
            <b-dropdown-item @click="$router.push('/home')">Home</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/imagecheck')">{{contentString.imageCheck[loginCountry]}}</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/approval')">{{contentString.approval[loginCountry]}}</b-dropdown-item>
            <b-dropdown-item @click="$router.push({name: 'reportcheck', params: {country: 'kr'}})">{{contentString.reportCheckKR[loginCountry]}}</b-dropdown-item>
            <b-dropdown-item @click="$router.push({name: 'reportcheck', params: {country: 'jp'}})">{{contentString.reportCheckJP[loginCountry]}}</b-dropdown-item>
            <b-dropdown-item @click="$router.push({name: 'reportcheck', params: {country: 'en'}})">{{contentString.reportCheckEN[loginCountry]}}</b-dropdown-item>
            <!-- TODO -->
            <b-dropdown-item @click="$router.push({name: 'reportcheck', params: {country: 'cn'}})">{{contentString.reportCheckCN[loginCountry]}}</b-dropdown-item>

            <!-- <b-dropdown-item @click="$router.push('/feeds')"
              >Feed</b-dropdown-item
            > -->

            <b-dropdown-item @click="$router.push('/feeds/search/feed')"
              >FeedContentSearch</b-dropdown-item
            >
            <b-dropdown-item @click="$router.push('/feeds/search/comment')"
              >FeedCommentSearch</b-dropdown-item
            >
            <b-dropdown-item @click="$router.push('/actionlog')">Action log</b-dropdown-item>
            <!--<b-dropdown-item @click="$router.push('/malerank')">{{contentString.maleRank[loginCountry]}}</b-dropdown-item>
                        <b-dropdown-item @click="$router.push('/femalerank')">{{contentString.femaleRank[loginCountry]}}</b-dropdown-item>
                        <b-dropdown-item @click="$router.push('/audiolist')">음성 리스트</b-dropdown-item> for test-->
            <b-dropdown-item @click="$router.push('/signout')"
              >Log Out</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import NavigationJson from "../assets/string/navigation.json";

export default {
  name: "Home",
  data() {
    return {
      loginCountry: this.$session.get("country"),
      contentString: NavigationJson.contentString,
    };
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push("/");
    }
  },
  methods: {
    changeLanguage(country) {
      this.$session.set("country", country);
      history.go(0);
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container {
  text-align: center;
}
</style>
