<template>
    <div>
        Redirecting....</div>
</template>

<script>
export default {
    name: 'App',
    mounted: function() {
        if (this.$session.exists()) {
            this.$session.destroy()
        }
        alert("Successfully Logged Out!")
        this.$router.push('/')
    }
}
</script>