<template>
    <div>
        <NavComponent />
        <b-container>
            <b-row>
                <b-col cols="7">
                    <p style="margin-top: 30px; margin-bottom:30px">
                        <b-input-group prepend="RoomID" class="mt-1">
                            <b-form-input v-model="roomID"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="info" @click="downloadAudio()" @keyup.enter="downloadAudio()" :disabled="downloading">
                                    <div v-if="!downloading">
                                        Download
                                    </div>
                                    <div v-if="downloading">
                                        <b-spinner small type="grow" style="margin-bottom:3px; margin-right:3px"></b-spinner>
                                        Downloading
                                    </div>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </p>
                </b-col>
                <b-col cols="4">
                    <p style="margin-top: 30px; margin-bottom:30px">
                        <b-input-group prepend="(Room/User)ID" class="mt-1">
                            <b-form-input v-model="userID"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="info" @click="findAudioByID()" @keyup.enter="findAudioByID()">
                                    Search
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </p>
                </b-col>
                <b-col cols="1">
                    <p style="margin-top: 30px; margin-bottom:30px">
                        <b-button :disabled="refreshing" pill variant="outline-info" @click="getList(), resetInput()">REFRESH</b-button>
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4"></b-col>
                <b-col cols="4">
                    
                </b-col>
                <b-col cols="4"></b-col>
            </b-row>
        </b-container>
        <p>TEST중 입니다. 관리자 외에는 사용하지 말아주세요.</p>
        <div v-if="items != null">
            <p v-if="items.length == 0"> Empty List </p>
        </div>
        <p v-if="items == null"><b-spinner variant="primary" label="Spinning"></b-spinner></p>
        <b-row>
            <b-col cols = "1"></b-col>
            <b-col>
                <b-table hover bordered responsive :items="items" :per-page="perPage" :current-page="currentPage">
                    <template #cell(roomID)="data">
                        <a @click="$router.push({ name: 'room', params: { roomid: data.value }})" :href="''">{{ data.value }}</a>
                    </template>
                </b-table>
            </b-col>
            <b-col cols = "1"></b-col>
        </b-row>
        <b-row>
            <b-col cols="2"></b-col>
            <b-col>
                <p>
                    <b-pagination style="justify-content: center" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                </p>
            </b-col>
            <b-col cols="2"></b-col>
        </b-row>
    </div>
</template>

<script>
import NavComponent from './NavComponent.vue'

export default {
    name: 'AudioList',
    components: {
        NavComponent
    },
    data() {
        return {
            perPage: 50,
            currentPage: 1,
            rawList: [],
            items: null,
            roomID: '',
            downloading: false,
            userID: '',
            searchItems: [],
            roomIDList: [],
            refreshing: true,
        }
    },
    computed: {
        rows() {
            if(this.items != null){
                return this.items.length
            }
            else return null
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            this.refreshing = true
            this.items = null
            this.$http.post("http://dev-manager.yplabs.net:3000/questions/getlist/",{
                "apiKey": "yplabsAudioList"
            })
                .then(res => {
                        this.items = []
                        this.rawList = res.data.data
                        this.updateList(this.rawList)
                        this.refreshing = false
                    },
                    (error) => {
                        alert(error);
                        this.refreshing = true
                    })
                .catch(err => {
                    alert(err);
                    this.refreshing = true
                })
        },
        resetInput(){
            this.roomID = ''
            this.userID = ''
        },
        updateList(callingList) {
            this.items = []
            this.searchItems = []
            this.roomIDList = []
            for (let i = 0; i < callingList.length; i++) {
                if(callingList[i].callTime == -1)
                    continue
                this.items.push({
                    id: callingList[i].id,
                    createdAt: this.$moment(callingList[i].createAt).format("YYYY/MM/DD HH:mm:ss"),
                    roomID: callingList[i].roomID,
                    userID: callingList[i].userID,
                    connectedAt: this.$moment(callingList[i].connAt).format("YYYY/MM/DD HH:mm:ss"),
                    disconnectedAt: this.$moment(callingList[i].disconnAt).format("YYYY/MM/DD HH:mm:ss"),
                    callTime: callingList[i].callTime,
                    status: callingList[i].status
                })
            }
        },
        downloadAudio() {
            this.downloading = true
            this.$http.get("http://audio.yplabs.net?room=" + this.roomID, {
                    'headers': {
                        'Authorization': 'connecting123!'
                    }
                })
                .then(res => {
                        this.downloading = false
                        if (res.data.error != undefined) {
                            alert(res.data.error)
                        } else if (res.status === 200) {
                            window.open(res.data.success)
                        }
                    },
                    (error) => {
                        alert(error);
                    })
                .catch(err => {
                    alert(err);
                })
        },
        findAudioByID() {
            this.updateList(this.rawList)

            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].userID == this.userID) {
                    this.roomIDList.push(this.items[i].roomID)
                }
            }
            for (let i = 0; i < this.items.length; i++) {
                this.roomIDList.forEach(roomID => {
                    if (this.items[i].roomID == roomID) {
                        this.searchItems.push({
                            id: this.items[i].id,
                            createdAt: this.items[i].createdAt,
                            roomID: this.items[i].roomID,
                            userID: this.items[i].userID,
                            connectedAt: this.items[i].connectedAt,
                            disconnectedAt: this.items[i].disconnectedAt,
                            callTime: this.items[i].callTime,
                            status: this.items[i].status
                        })
                    }
                })

                if (this.userID == this.items[i].roomID){
                    this.searchItems.push({
                            id: this.items[i].id,
                            createdAt: this.items[i].createdAt,
                            roomID: this.items[i].roomID,
                            userID: this.items[i].userID,
                            connectedAt: this.items[i].connectedAt,
                            disconnectedAt: this.items[i].disconnectedAt,
                            callTime: this.items[i].callTime,
                            status: this.items[i].status
                        })
                }
            }

            this.items = this.searchItems
            this.$toast.success('검색 완료', {
                        position: 'top',
                        duration: 1200
                    })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
