<template>
    <div id="app">
        <NavComponent />
        <b-container>
            <b-row>
                <b-col>
                    <h5>Current Calling Users</h5>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-table striped hover :items="items"></b-table>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import NavComponent from './NavComponent.vue'

export default {
    name: 'calling',
    components: {
        NavComponent
    },
    data() {
        return {
            items: [
          { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
          { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
          { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
          { age: 38, first_name: 'Jami', last_name: 'Carney' }
        ]
        }
    },
    beforeCreate(){
        this.$http.get('http://15.165.34.35/api/stats', {
                headers: { "Authorization": "connecting123!",
                 },
            })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                this.data = null
                alert(err)
            })
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.container {
    text-align: center;
}
</style>
