<template>
  <div>
    <NavComponent />
    <b-container>
      <div v-if="data == ''">
        <p v-if="data == ''" style="text-align: center">
          <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem"
            label="Large Spinner"
          ></b-spinner>
        </p>
      </div>
      <div v-if="data == null">
        <p>
          {{ contentString.noList[loginCountry] }} <br />{{
            contentString.refresh[loginCountry]
          }}
          <br />{{ countDown }}
        </p>
      </div>
      <div v-if="data != null">
        <div v-if="data.length > 0">
          <p>totalCount: {{ totalCount }}</p>
          <b-row>
            <b-col cols="1"> </b-col>
            <b-col style="text-align: -webkit-center">
              <b-card style="max-width: 600px">
                <p style="text-align: center">
                  <strong
                    >{{ csConfirm === 3 ? "보류된 신고에요." : "" }}
                  </strong>
                </p>
                <b-card-text style="text-align: left">
                  <p style="text-align: center"><strong>User Info</strong></p>
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.country[loginCountry]
                      }}</strong></b-col
                    >
                    <b-col>{{ reportUser.country }}</b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col><strong>{{
                        contentString.emailSex[loginCountry]
                      }}</strong></b-col>
                    <b-col>{{ reportUser.email }}({{ reportUser.sex }})</b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.nickname[loginCountry]
                      }}</strong></b-col
                    >
                    <b-col>
                      <a v-bind:href="this.reportUserAdminUrl">
                        {{ reportUser.nickname }}
                      </a>
                    </b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.reportCategory[loginCountry]
                      }}</strong></b-col
                    >
                    <b-col>
                      <!-- 신고 유형 변경 드롭 다운 -->
                      <b-dropdown
                        id="dropdown-1"
                        :text="reportCategory"
                        size="sm"
                      >
                        <b-dropdown-item
                          v-for="reportDomain in getReportCategoryTypes()"
                          :key="reportDomain"
                          @click="selectReportDomain(reportDomain)"
                          >{{ reportDomain }}
                          </b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                   
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.reportTime[loginCountry]
                      }}</strong></b-col
                    >
                    <b-col style="word-break: keep-all">{{
                      reportTime | moment("YYYY-MM-DD HH:mm:ss")
                    }}</b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col><strong>obj_id</strong></b-col>

                    <b-col>
                      <b-button
                        @click="
                          clickObjID(
                            reportCategory,
                            targetUser.uuid,
                            reportObjId
                          )
                        "
                        class="detailBtn"
                        size="sm"
                        variant="outline-info"
                        >{{ reportObjId }}</b-button
                      >
                    </b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong
                        >{{ contentString.voiceScore[loginCountry] }}/{{
                          contentString.totalCall[loginCountry]
                        }}</strong
                      ></b-col
                    >
                    <b-col
                      >{{ reportUser.details.voice_score }}/{{
                        reportUser.details.total_call
                      }}</b-col
                    >
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong
                        >{{ contentString.chatScore[loginCountry] }}/{{
                          contentString.totalChat[loginCountry]
                        }}</strong
                      ></b-col
                    >
                    <b-col
                      >{{ reportUser.details.chat_score }}/{{
                        reportUser.details.total_chat
                      }}</b-col
                    >
                  </b-row>
                </b-card-text>
                <b-button v-b-modal.modal-1 variant="outline-info" size="sm">{{
                  contentString.reportHistory[loginCountry]
                }}</b-button>
                <!--이전 신고 모달 -->
                <b-modal
                  hide-footer
                  id="modal-1"
                  :title="contentString.reportHistory[loginCountry]"
                >
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.restoredCount[loginCountry]
                      }}</strong></b-col
                    >
                    <b-col>{{ reportUser.details.total_restore }}</b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong
                        >{{ contentString.totalCall[loginCountry] }}/{{
                          contentString.reportCount[loginCountry]
                        }}</strong
                      ></b-col
                    >
                    <b-col
                      >{{ reportUser.details.total_call }}/{{
                        reportUser.details.total_report
                      }}</b-col
                    >
                  </b-row>
                  <b-table
                    :items="reportUserHistory"
                    :fields="reportUserHistoryField"
                    small
                    bordered
                  ></b-table>
                  <span class="moreBtn">
                    <b-button
                      variant="info"
                      size="sm"
                      :disabled="endOfReportDetails"
                      @click="getReportDetails()"
                      >{{ contentString.readMore[loginCountry] }}</b-button
                    >
                  </span>
                </b-modal>
              </b-card>
            </b-col>
            <b-col cols="1"> </b-col>
          </b-row>
          <!-- 신고 대상 유저 카드 -->
          <b-row>
            <b-col cols="1"> </b-col>
            <b-col style="text-align: -webkit-center">
              <b-card style="max-width: 600px">
                <b-card-text style="text-align: left">
                  <p style="text-align: center">
                    <strong>TargerUser Info</strong>
                  </p>
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.country[loginCountry]
                      }}</strong></b-col
                    >
                    <b-col>{{ targetUser.country }}</b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.emailSex[loginCountry]
                      }}</strong></b-col
                    >
                    <b-col>{{ targetUser.email }}({{ targetUser.sex }})</b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.nickname[loginCountry]
                      }}</strong></b-col
                    >
                    <b-col>
                      <a v-bind:href="this.targetUserAdminUrl">
                        {{ targetUser.nickname }}
                      </a></b-col
                    >
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong
                        >{{ contentString.voiceScore[loginCountry] }}/{{
                          contentString.totalCall[loginCountry]
                        }}</strong
                      ></b-col
                    >
                    <b-col
                      >{{ targetUser.details.voice_score }}/{{
                        targetUser.details.total_call
                      }}</b-col
                    >
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong
                        >{{ contentString.chatScore[loginCountry] }}/{{
                          contentString.totalChat[loginCountry]
                        }}</strong
                      ></b-col
                    >
                    <b-col
                      >{{ targetUser.details.chat_score }}/{{
                        targetUser.details.total_chat
                      }}</b-col
                    >
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.reportType1[loginCountry]
                      }}</strong></b-col
                    >
                    <b-col>{{ reportMidCategory }}</b-col>
                  </b-row>

                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.reportType2[loginCountry]
                      }}</strong></b-col
                    >
                    <!-- 신고 사유 모달 -->
                    <b-modal
                      hide-footer
                      id="modal-5"
                      :title="contentString.reportType2[loginCountry]"
                    >
                      <b-form-group>
                        <b-form-checkbox-group
                          size="sm"
                          v-model="selectedType"
                          :options="this.reasonList"
                          button-variant="outline-primary"
                          stacked
                          buttons
                          style="width: 100%; word-break: keep-all"
                        ></b-form-checkbox-group>
                      </b-form-group>
                      <div class="d-block text-center lg">
                        <b-button
                          variant="success"
                          @click="$bvModal.hide('modal-5')"
                          pill
                          style="margin-right: 15px"
                        >
                          {{ contentString.accept[loginCountry] }}
                        </b-button>
                        <b-button
                          variant="danger"
                          @click="$bvModal.hide('modal-5'), resetReportType()"
                          pill
                          style="margin-left: 15px"
                        >
                          {{ contentString.reject[loginCountry] }}
                        </b-button>
                      </div>
                    </b-modal>
                    <b-col>
                      <b-button
                        class="detailBtn"
                        size="sm"
                        v-b-modal.modal-5
                        variant="outline-info"
                        >{{ parseKey(selectedType[0]) }}</b-button
                      >
                    </b-col>
                  </b-row>

                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.reportContent[loginCountry]
                      }}</strong></b-col
                    >
                    <b-col>{{ reportContent }}</b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.purchaseAccumulate[loginCountry]
                      }}</strong></b-col
                    >
                    <b-col>{{ targetUser.purchase_accumulate }}</b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col><strong>subscription</strong></b-col>
                    <b-col style="word-break: keep-all">{{
                      targetUserSub | moment("YYYY-MM-DD HH:mm:ss")
                    }}</b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col style="padding-right: 0px" cols="7"
                      ><strong>{{
                        contentString.reportedCount[loginCountry]
                      }}</strong></b-col
                    >
                    <!-- 누적 신고 접수 모달 -->
                    <b-modal
                      hide-footer
                      id="modal-2"
                      :title="contentString.reportedList[loginCountry]"
                    >
                      <b-table
                        :items="targetUserHistory"
                        :fields="targetUserHistoryField"
                        small
                        bordered
                      ></b-table>
                      <span class="moreBtn">
                        <b-button
                          variant="info"
                          size="sm"
                          :disabled="endOfTargetHistory"
                          @click="getTargetHistory()"
                          >{{ contentString.readMore[loginCountry] }}</b-button
                        >
                      </span>
                    </b-modal>
                    <b-col style="margin-left: -8%">
                      <b-button
                        class="detailBtn"
                        size="sm"
                        v-b-modal.modal-2
                        variant="outline-info"
                        >{{
                          targetUser.resolved_criminal_report_accumulate
                        }}</b-button
                      >
                    </b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.recentSanction[loginCountry]
                      }}</strong></b-col
                    >
                    <!-- 누적 제재 모달 -->
                    <b-modal
                      hide-footer
                      id="modal-3"
                      :title="contentString.sanctionList[loginCountry]"
                    >
                      <b-dropdown
                        id="dropdown-1"
                        :text="currentControlDomainType"
                        class="m-md-2"
                      >
                        <b-dropdown-item
                          v-for="controlDomainType in getControlDomainTypes()"
                          :key="controlDomainType"
                          @click="selectControlDomainType(controlDomainType)"
                          >{{ controlDomainType }}</b-dropdown-item
                        >
                      </b-dropdown>
                      <b-table
                        :items="targetUserControlHistory"
                        :fields="targetUserControlHistoryField"
                        bordered
                        small
                      >
                        <template #cell(is_active)="data">
                          <b-form-checkbox
                            v-model="data.item.is_active"
                            disabled
                          ></b-form-checkbox>
                        </template>
                      </b-table>
                      <span class="moreBtn">
                        <b-button
                          variant="info"
                          size="sm"
                          :disabled="endOfTargetDetails"
                          @click="getTargetDetails()"
                          >{{ contentString.readMore[loginCountry] }}</b-button
                        >
                      </span>
                    </b-modal>

                    <b-col>
                      <b-button
                        class="detailBtn"
                        size="sm"
                        v-b-modal.modal-3
                        variant="outline-info"
                        >{{
                          targetUser.latest_user_control_key_or_null
                        }}</b-button
                      >
                    </b-col>
                  </b-row>
                  <b-row class="userInfo">
                    <b-col
                      ><strong>{{
                        contentString.sanctionReason[loginCountry]
                      }}</strong></b-col
                    >
                    <!-- 제재 사유 모달 -->
                    <b-modal
                      hide-footer
                      id="modal-4"
                      :title="contentString.sanctionReason[loginCountry]"
                    >
                      <b-form-group>
                        <b-form-checkbox-group
                          size="sm"
                          v-model="selectedReason"
                          :options="this.reasonOptions"
                          button-variant="outline-primary"
                          stacked
                          buttons
                          style="width: 100%; word-break: keep-all"
                        ></b-form-checkbox-group>
                      </b-form-group>
                      <div class="d-block text-center lg">
                        <b-button
                          variant="success"
                          @click="$bvModal.hide('modal-4')"
                          pill
                          style="margin-right: 15px"
                        >
                          {{ contentString.accept[loginCountry] }}
                        </b-button>
                        <b-button
                          variant="danger"
                          @click="$bvModal.hide('modal-4'), resetReportReason()"
                          pill
                          style="margin-left: 15px"
                        >
                          {{ contentString.reject[loginCountry] }}
                        </b-button>
                      </div>
                    </b-modal>
                    <b-col>
                      <b-button
                        class="detailBtn"
                        size="sm"
                        v-b-modal.modal-4
                        variant="outline-info"
                        >{{ parseKey(selectedReason[0]) }}</b-button
                      >
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col cols="1"> </b-col>
          </b-row>
        </div>
      </div>
      <b-row>
        <b-col>
          <p class="selectAction">
            <b-button-group>
              <b-button
                @click="checkAnotherAction('WARNING')"
                :pressed.sync="actionBtn.WARNING"
                class="resultBtn"
                pill
                size="sm"
                variant="outline-info"
                >{{ contentString.warning[loginCountry] }}</b-button
              >
              <b-button
                @click="checkAnotherAction('PAUSE_DAY1')"
                :pressed.sync="actionBtn.PAUSE_DAY1"
                class="resultBtn"
                pill
                size="sm"
                variant="outline-info"
                >{{ contentString.pause_1[loginCountry] }}</b-button
              >
              <b-button
                @click="checkAnotherAction('PAUSE_DAY3')"
                :pressed.sync="actionBtn.PAUSE_DAY3"
                class="resultBtn"
                pill
                size="sm"
                variant="outline-info"
                >{{ contentString.pause_3[loginCountry] }}</b-button
              >
              <b-button
                @click="checkAnotherAction('PAUSE_DAY5')"
                :pressed.sync="actionBtn.PAUSE_DAY5"
                class="resultBtn"
                pill
                size="sm"
                variant="outline-info"
                >{{ contentString.pause_5[loginCountry] }}</b-button
              >
              <b-button
                @click="checkAnotherAction('PAUSE_DAY7')"
                :pressed.sync="actionBtn.PAUSE_DAY7"
                class="resultBtn"
                pill
                size="sm"
                variant="outline-info"
                >{{ contentString.pause_7[loginCountry] }}</b-button
              >
              <b-button
                @click="checkAnotherAction('PAUSE_DAY365')"
                :pressed.sync="actionBtn.PAUSE_DAY365"
                class="resultBtn"
                pill
                size="sm"
                variant="outline-info"
                >{{ contentString.pause_365[loginCountry] }}</b-button
              >
              <b-button
                @click="checkAnotherAction('BANNED')"
                :pressed.sync="actionBtn.BANNED"
                class="resultBtn"
                pill
                size="sm"
                variant="outline-info"
                >{{ contentString.ban[loginCountry] }}</b-button
              >
            </b-button-group>
          </p>
          <b-button-group>
            <b-button
              class="acceptBtn"
              pill
              variant="success"
              @click="pendReport()"
              >{{ contentString.pending[loginCountry] }}</b-button
            >
            <b-button
              class="acceptBtn"
              pill
              variant="success"
              @click="applyDirectControl()"
              >{{ contentString.pass[loginCountry] }}</b-button
            >
            <b-button
              class="acceptBtn"
              pill
              variant="success"
              @click="applyToCleanlinessLab()"
              >{{ contentString.applyScore[loginCountry] }}</b-button
            >
            <b-button
              class="acceptBtn"
              pill
              variant="success"
              @click="rejectReport()"
              >{{ contentString.notApplyScore[loginCountry] }}</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavComponent from "./NavComponent.vue";
import ReportCheckJson from "../assets/string/reportCheck.json";

export default {
  name: "ReportCheck",
  components: {
    NavComponent,
  },
  watch: {
    selectedReason: function () {
      if (this.selectedReason.length > 1) {
        this.selectedReason.splice(0, 1);
      }
      if (this.selectedReason.length == 0) {
        this.selectedReason = [this.contentString.choice[this.loginCountry]];
      }
    },
    selectedType: function () {
      if (this.selectedType.length > 1) {
        this.selectedType.splice(0, 1);
      }
      if (this.selectedType.length == 0) {
        this.selectedType = [this.contentString.choice[this.loginCountry]];
      }
    },
    data: function () {
      if (this.data == null) {
        this.refreshtimer = setInterval(() => {
          this.$router.go(0);
          clearTimeout(this.refreshtimer);
        }, 5000);
        this.refreshCountDown();
      }
    },
    "$route.params.country"(newCountry, oldCountry) {
      if (newCountry !== oldCountry) {
        this.$router.go(0);
      }
    },
  },
  data() {
    return {
      csConfirm: 0,
      selectedReason: [],
      selectedType: [],
      refreshtimer: null,
      data: "",
      reportDomain:"",
      reportId: "",
      reportCategory: "",
      reportMidCategory: "",
      reportType: "",
      reportContent: "",
      reportTime: "",
      reportUserSub: "",
      targetUserSub: "",
      reportUser: {
        details: {},
      },
      targetUser: {
        details: {},
      },
      reportUserHistoryField: [
        {
          key: "type",
        },
        {
          key: "subType",
          thStyle: { width: "155px" },
        },
        {
          key: "content",
        },
        {
          key: "date",
          thStyle: { width: "110px" },
        },
      ],
      reportUserHistory: [],
      targetUserHistoryField: [
        {
          key: "type",
        },
        {
          key: "subType",
          thStyle: { width: "155px" },
        },
        {
          key: "content",
        },
        {
          key: "date",
          thStyle: { width: "110px" },
        },
      ],
      targetUserHistory: [],
      filteredUserControls: [],
      targetUserControlHistoryField: [
        // 누적 제재 모달 표 column
        {
          key: "domain",
          label: "제재 도메인",
        },
        {
          key: "type",
          thStyle: { width: "110px" },
        },
        {
          key: "reason",
          label: "사유",
        },
        {
          key: "date",
          thStyle: { width: "110px" },
        },
        {
          key: "is_active",

          thStyle: { width: "110px" },
        },
      ],
      targetUserControlHistory: [], // 누적 제재 내역
      targetReportedCount: [{}], // 마지막 제재 세부정보
      reportTypeList: [],
      reportReasonList: [],
      reportDetailsPage: 1,
      endOfReportDetails: false,
      targetDetailsPage: 1,
      endOfTargetDetails: false,
      targetHistoryPage: 1,
      endOfTargetHistory: false,
      restoreTicket: false,
      cleanLab: false,
      currentControlDomainType: "ALL",
      actionBtn: {
        WARNING: false,
        PENALTY_3: false,
        PENALTY_7: false,
        PAUSE_DAY1: false,
        PAUSE_DAY3: false,
        PAUSE_DAY5: false,
        PAUSE_DAY7: false,
        PAUSE_DAY365: false,
        BANNED: false,
      },
      reasonOptions: ReportCheckJson.reasonOptions,
      typeString: ReportCheckJson.typeString,
      loginCountry: this.$session.get("country"),
      contentString: ReportCheckJson.contentString,
      midCategory: ReportCheckJson.midCategory,
      countDown: 5,
      controlDomainTypes: ["ALL", "CALL", "FEED", "SWIPE"],
    };
  },
  
  beforeDestroy() {
    clearTimeout(this.refreshtimer);
  },
  beforeCreate() {
    this.$http
      .get(process.env.VUE_APP_API + "/management/report/control_types/", {
        headers: { Authorization: this.$session.get("token") },
      })
      .then((res) => {
        if (res.status === 200) {
          this.reportTypeList = res.data.data;
        }
      })
      .catch((err) => {
        alert("report types Error" + err);
      });
    this.$http
      .get(process.env.VUE_APP_API + "/management/report/control_reasons/", {
        headers: { Authorization: this.$session.get("token") },
      })
      .then((res) => {
        if (res.status === 200) {
          this.reportReasonList = res.data.data;
          this.reasonList = res.data.data.map((reason) => {
            let translatedText = this.contentString[reason.key];
            switch (this.loginCountry) {
              case "EN":
                translatedText = reason.description_en;
                break;
              case "JP":
                translatedText = reason.description_ja;
                break;
              default:
                translatedText = reason.description_ko;
                break;
            }
            return {
              id: reason.id,
              value: reason.key,
              text: translatedText,
            };
          });
          this.reasonOptions = this.reasonList;
          this.typeString = this.reasonList;
        }
      })
      .catch((err) => {
        alert("report reasons Error" + err);
      });

    const country = this.$route.path.split("/").pop();
    this.$http
      .get(process.env.VUE_APP_API + "/management/report/reports/", {
        params: {
          page: 0,
          country: country.toUpperCase(),
        },
        headers: { Authorization: this.$session.get("token") },
      })
      .then((res) => {
        if (res.status === 200 && res.data.data.length > 0) {
          this.data = res.data.data;
          this.updateInfo();
        } else {
          this.data = null;
        }
      })
      .catch((err) => {
        this.data = null;
        alert("report list" + err);
      });
  },
  methods: {
    parseKey(key) {
      let text = key
      this.reasonOptions.map((reason) => {
        if (reason.value == key) {
          text = reason.text;
        }
      });
      return text;
    },
    moveToReportDetail(type, userId, targetId) {
      if (type == "FEED") {
        const routeData = this.$router.resolve({
          name: "feedContent",
          params: { id: targetId },
        });
        window.open(routeData.href, "_blank");
      } else if (type == "COMMENT") {
        const routeData = this.$router.resolve({
          name: "feedComment",
          params: { user_id: userId, comment_id: targetId },
        });
        window.open(routeData.href, "_blank");
      }
    },
    updateInfo() {
      console.log("updateInfo", this.data[0]);
      this.selectedReset();
      const currReport = this.data[0]
      this.totalCount = this.data.length;
      this.reportId = currReport.id;
      this.csConfirm = currReport.cs_confirm;
      this.reportCategory = currReport.report_category;
      this.reportObjId = currReport.obj_id;
      this.reportMidCategory = this.getMidTypeText(
        currReport.report_mid_category
      );
      this.reportType = currReport.report_type;
      this.reportTime = currReport.created_at;


      this.checkDefaultReason();
      this.reportContent = currReport.report_content;
      this.reportUser = currReport.report_user;
      this.targetUser = currReport.target_user;
      this.reportUserAdminUrl = `${process.env.VUE_APP_API}/ko/yp/manage/service/admin/account/user/${this.reportUser.id}/change/`;
      this.targetUserAdminUrl = `${process.env.VUE_APP_API}/ko/yp/manage/service/admin/account/user/${this.targetUser.id}/change/`;
      this.getReasonScore();
      if (currReport.target_user.subscription_status != null) {
        this.targetUserSub =
          currReport.target_user.subscription_status.expired_at;
      } else {
        this.targetUserSub = "null";
      }

      for (let i = 0; i < this.reportUser.details.recent_reports.length; i++) {
        this.reportUserHistory.push({
          type: this.getMidTypeText(
            this.reportUser.details.recent_reports[i].report_mid_category
          ),
          subType: this.getTypeText(
            this.reportUser.details.recent_reports[i].report_type
          ),
          content: this.reportUser.details.recent_reports[i].report_content,
          date: this.$moment(
            this.reportUser.details.recent_reports[i].created_at
          ).format("YY/MM/DD_HH:mm"),
        });
      }
      for (
        let i = 0;
        i < this.targetUser.resolved_criminal_reports.length;
        i++
      ) {
        const resolved_criminal_report =
          this.targetUser.resolved_criminal_reports[i];
        this.targetUserHistory.push({
          type: resolved_criminal_report.domain,
          subType: this.getTypeText(resolved_criminal_report.report_type),
          content: resolved_criminal_report.report_content,
          date: this.$moment(resolved_criminal_report.created_at).format(
            "YY/MM/DD_HH:mm"
          ),
        });
      }
      for (let i = 0; i < this.targetUser.user_controls.length; i++) {
        const targetUserControl = this.targetUser.user_controls[i];

        this.targetUserControlHistory.push({
          type: targetUserControl.control_type,
          reason: this.getReasonText(targetUserControl.reason),
          date: this.$moment(targetUserControl.created_at).format(
            "YY/MM/DD_HH:mm"
          ),
          is_active: targetUserControl.is_active,
          domain: targetUserControl.domain,
        });
      }

      this.targetReportedCount = [{}];
      this.targetReportedCount[0][
        this.contentString.warning[this.loginCountry]
      ] = this.targetUser.user_control_distribution.WARNING;
      this.targetReportedCount[0][
        this.contentString.pause_1[this.loginCountry]
      ] = this.targetUser.user_control_distribution.PAUSE_DAY1;
      this.targetReportedCount[0][
        this.contentString.pause_3[this.loginCountry]
      ] = this.targetUser.user_control_distribution.PAUSE_DAY3;
      this.targetReportedCount[0][
        this.contentString.pause_5[this.loginCountry]
      ] = this.targetUser.user_control_distribution.PAUSE_DAY5;
      this.targetReportedCount[0][
        this.contentString.pause_7[this.loginCountry]
      ] = this.targetUser.user_control_distribution.PAUSE_DAY7;
    },
    clickObjID(type, userId, targetId) {
      if (this.reportCategory == "FEED") {
        const routeData = this.$router.resolve({
          name: "feedContent",
          params: { id: targetId },
        });
        window.open(routeData.href, "_blank");
      } else if (this.reportCategory == "COMMENT") {
        const routeData = this.$router.resolve({
          name: "feedComment",
          params: { user_id: userId, comment_id: targetId },
        });
        window.open(routeData.href, "_blank");
      } else if (["CHAT", "CONVERSATION"].includes(this.reportCategory)) {
        const routeData = this.$router.resolve({
          name: "SendbirdMessage",
          params: { channelUrl: this.reportObjId },
        });
        window.open(routeData.href, "_blank");
      }
    },
    getReportDetails() {
      this.$http
        .get(process.env.VUE_APP_API + "/management/report/reports/recent/", {
          params: {
            page: this.reportDetailsPage,
            user_id: this.reportUser.id,
          },
          headers: { Authorization: this.$session.get("token") },
        })
        .then((res) => {
          if (res.status === 200) {
            for (let i = 0; i < res.data.data.length; i++) {
              this.reportUserHistory.push({
                type: this.getMidTypeText(res.data.data[i].report_mid_category),
                subType: this.getTypeText(res.data.data[i].report_type),
                content: res.data.data[i].report_content,
                date: this.$moment(res.data.data[i].created_at).format(
                  "YY/MM/DD_HH:mm"
                ),
              });
            }
            this.reportDetailsPage += 1;
            if (res.data.data.length < 8) {
              this.endOfReportDetails = true;
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    updateReport(){
      // alert(this.$session.get("token"));
      
      this.$http
      .patch(process.env.VUE_APP_API+ `/management/report/reports/${this.reportId}/`,{
        domain: this.reportCategory,
      },{
        headers: { Authorization: this.$session.get("token") },
      })
      .then((res) => {
        this.$toast.success(res.data.message, {
          position: "top",
          duration: 1200,
        });
      })
      .catch((err) =>{
        alert("updateReport",err);
        this.updateInfo()
      })

    },
    getTargetHistory() {
      this.$http
        .get(
          process.env.VUE_APP_API +
            "/management/report/reports/recent/criminals/resolved/",
          {
            params: {
              page: this.targetHistoryPage,
              user_id: this.targetUser.id,
            },
            headers: { Authorization: this.$session.get("token") },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            for (let i = 0; i < res.data.data.length; i++) {
              this.targetUserHistory.push({
                type: res.data.data[i].domain,
                subType: this.getTypeText(res.data.data[i].report_type),
                content: res.data.data[i].report_content,
                date: this.$moment(res.data.data[i].created_at).format(
                  "YY/MM/DD_HH:mm"
                ),
              });
            }
            this.targetHistoryPage += 1;
            if (res.data.data.length < 8) {
              this.endOfTargetHistory = true;
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    getTargetDetails() {
      this.$http
        .get(process.env.VUE_APP_API + "/management/report/user_controls/", {
          params: {
            page: this.targetDetailsPage,
            user_id: this.targetUser.id,
          },
          headers: { Authorization: this.$session.get("token") },
        })
        .then((res) => {
          if (res.status === 200) {
            for (let i = 0; i < res.data.data.length; i++) {
              this.targetUserControlHistory.push({
                type: res.data.data[i].control_type,
                reason: this.getReasonText(res.data.data[i].reason),
                date: this.$moment(res.data.data[i].created_at).format(
                  "YY/MM/DD_HH:mm"
                ),
              });
            }
            this.targetDetailsPage += 1;
            if (res.data.data.length < 8) {
              this.endOfTargetDetails = true;
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    checkAnotherAction(pressedBtn) {
      for (let [key, value] of Object.entries(this.actionBtn)) {
        if (value == true && key != pressedBtn) {
          this.actionBtn[key] = false;
        }
      }
    },
    pendReport() {
      this.$http
        .post(
          process.env.VUE_APP_API + "/management/report/reports/",
          {
            report_id: this.reportId,
            direct_control: false,
            pending: true,
          },
          {
            headers: { Authorization: this.$session.get("token") },
          }
        )
        .then((res) => {
          if (res.status === 200 && this.data != null) {
            this.$toast.success(res.data.message, {
              position: "top",
              duration: 1200,
            });

            this.data.splice(0, 1);
            if (this.data.length <= 0) {
              this.wip();
            } else {
              this.updateInfo();
            }
          } else if (res.status === 400) {
            this.$toast.warning(res.data.message, {
              position: "top",
              duration: 1200,
            });
          } else if (this.data == null) {
            this.$toast.warning(
              this.contentString.noListMsg[this.loginCountry],
              {
                position: "top",
                duration: 1200,
              }
            );
          }
        })
        .catch((err) => {
          console.log("pendReport",err);
          this.$toast.error(this.contentString.errorMsg[this.loginCountry], {
            position: "top",
            duration: 1200,
          });
        });
    },
    applyDirectControl() {
      this.$http
        .post(
          process.env.VUE_APP_API + "/management/report/reports/",
          {
            report_id: this.reportId,
            direct_control: true,
            user_control: this.getTypeReason(),
            report_reason: this.selectedType[0],
          },
          {
            headers: { Authorization: this.$session.get("token") },
          }
        )
        .then((res) => {
          if (res.status === 200 && this.data != null) {
            this.$toast.success(res.data.message, {
              position: "top",
              duration: 1200,
            });

            this.data.splice(0, 1);
            if (this.data.length <= 0) {
              this.wip();
            } else {
              this.updateInfo();
            }
          } else if (res.status === 400) {
            this.$toast.warning(res.data.message, {
              position: "top",
              duration: 1200,
            });
          } else if (this.data == null) {
            this.$toast.warning(
              this.contentString.noListMsg[this.loginCountry],
              {
                position: "top",
                duration: 1200,
              }
            );
          }
        })
        .catch((err) => {
          console.log("apply Direct",err)
          const errMsg =
            this.contentString.errorMsg[this.loginCountry] +
            "\n" +
            JSON.stringify(err.response.data);
          this.$toast.error(errMsg, {
            position: "top",
            duration: 5000,
          });
        });
    },
    applyToCleanlinessLab() {
      let saveBody;

      if (this.getTypeReason() != null) {
        saveBody = {
          report_id: this.reportId,
          direct_control: false,
          user_control: this.getTypeReason(), // {"type": i, "reason": i},
          cs_confirm: true,
        };
      } else {
        saveBody = {
          report_id: this.reportId,
          direct_control: false,
          cs_confirm: true,
        };
      }
      if (
        this.selectedType[0] != [this.contentString.choice[this.loginCountry]]
      ) {
        saveBody.report_reason = this.selectedType[0];
      }

      this.$http
        .post(
          process.env.VUE_APP_API + "/management/report/reports/",
          saveBody,
          {
            headers: { Authorization: this.$session.get("token") },
          }
        )
        .then((res) => {
          if (res.status === 200 && this.data != null) {
            this.$toast.success(res.data.message, {
              position: "top",
              duration: 1200,
            });

            this.data.splice(0, 1);
            if (this.data.length <= 0) {
              this.wip();
            } else {
              this.updateInfo();
            }
          } else if (res.status === 400) {
            this.$toast.warning(res.data.message, {
              position: "top",
              duration: 1200,
            });
          } else if (this.data == null) {
            this.$toast.warning(
              this.contentString.noListMsg[this.loginCountry],
              {
                position: "top",
                duration: 1200,
              }
            );
          }
        })
        .catch((err) => {

          console.log('applyToCleanlinessLab',err)
          const errMsg =
            this.contentString.errorMsg[this.loginCountry] +
            "\n" +
            JSON.stringify(err.response.data);
          this.$toast.error(errMsg, {
            position: "top",
            duration: 5000,
          });
        });
    },
    /**
     * 신고 반려 처리
     */
     rejectReport() {
      let saveBody;
      if (this.getTypeReason() != null) {
        saveBody = {
          report_id: this.reportId,
          direct_control: false,
          user_control: this.getTypeReason(), // {"type": i, "reason": i},
          cs_confirm: false,
        };
      } else {
        saveBody = {
          report_id: this.reportId,
          direct_control: false,
          cs_confirm: false,
        };
      }
      console.log(saveBody);
      if (
        this.selectedType[0] != [this.contentString.choice[this.loginCountry]]
      ) {
        saveBody.report_reason = this.selectedType[0];
      }

      this.$http
        .post(
          process.env.VUE_APP_API + "/management/report/reports/",
          saveBody,
          {
            headers: { Authorization: this.$session.get("token") },
          }
        )
        .then((res) => {
          if (res.status === 200 && this.data != null) {
            this.$toast.success(res.data.message, {
              position: "top",
              duration: 1200,
            });

            this.data.splice(0, 1);
            if (this.data.length <= 0) {
              this.wip();
            } else {
              this.updateInfo();
            }
          } else if (res.status === 400) {
            this.$toast.warning(res.data.message, {
              position: "top",
              duration: 1200,
            });
          } else if (this.data == null) {
            this.$toast.warning(
              this.contentString.noListMsg[this.loginCountry],
              {
                position: "top",
                duration: 1200,
              }
            );
          }
        })
        .catch((err) => {
          console.log("notApplyReport", err);
          this.$toast.error(this.contentString.errorMsg[this.loginCountry], {
            position: "top",
            duration: 1200,
          });
        });
    },
    wip() {
      this.$router.go(0);
    },
    selectedReset() {
      this.selectedReason = [this.contentString.choice[this.loginCountry]];
      this.selectedType = [this.contentString.choice[this.loginCountry]];
      this.reportUserHistory = [];
      this.targetUserHistory = [];

      this.targetReportedCount = [{}];
      this.targetReportedCount[0][
        this.contentString.warning[this.loginCountry]
      ] = 0;
      this.targetReportedCount[0][
        this.contentString.pause_1[this.loginCountry]
      ] = 0;
      this.targetReportedCount[0][
        this.contentString.pause_3[this.loginCountry]
      ] = 0;
      this.targetReportedCount[0][
        this.contentString.pause_5[this.loginCountry]
      ] = 0;
      this.targetReportedCount[0][
        this.contentString.pause_7[this.loginCountry]
      ] = 0;

      this.reportDetailsPage = 1;
      this.endOfReportDetails = false;
      this.targetDetailsPage = 1;
      this.endOfTargetDetails = false;
      this.targetHistoryPage = 1;
      this.endOfTargetHistory = false;
      this.cleanLab = false;
      this.actionBtn = {
        WARNING: false,
        PENALTY_3: false,
        PENALTY_7: false,
        PAUSE_DAY1: false,
        PAUSE_DAY3: false,
        PAUSE_DAY5: false,
        PAUSE_DAY7: false,
        PAUSE_DAY365: false,
        BANNED: false,
      };
    },
    getTypeReason() {
      let userControl = {
        control_type: -1,
        reason: -1,
      };

      let selectedType = Object.keys(this.actionBtn).find(
        (key) => this.actionBtn[key] == true
      );
      for (let i = 0; i < this.reportTypeList.length; i++) {
        if (this.reportTypeList[i].key == selectedType) {
          userControl.control_type = Number(this.reportTypeList[i].id);
        }
      }

      for (let i = 0; i < this.reportReasonList.length; i++) {
        if (this.reportReasonList[i].key == this.selectedReason[0]) {
          userControl.reason = Number(this.reportReasonList[i].id);
        }
      }
      if (userControl.control_type == -1 && userControl.reason == -1) {
        return null;
      } else return userControl;
    },
    getTypeText(type) {
      for (let i = 0; i < this.typeString.length; i++) {
        if (type == this.typeString[i].value) {
          return this.typeString[i].text;
        }
      }
      return null;
    },
    getMidTypeText(midType) {
      for (let i = 0; i < this.midCategory[this.loginCountry].length; i++) {
        if (midType == this.midCategory[this.loginCountry][i].value) {
          return this.midCategory[this.loginCountry][i].text;
        }
      }
    },
    getReasonText(reason) {
      for (let i = 0; i < this.reasonOptions.length; i++) {
        if (reason == this.reasonOptions[i].value) {
          return this.reasonOptions[i].text;
        }
      }
      return null;
    },
    getReasonScore() {
      this.$http
        .get(process.env.VUE_APP_API + "/management/control_reasons/scores", {
          headers: {
            Authorization: this.$session.get("token"),
            "Remote-User": this.data[0].target_user.uuid,
          },
        })
        .then((res) => {
          
          let prefix_text = ""
          res.data.data = res.data.data.filter((score) => score?.key != null);
          this.reasonOptions = this.reasonOptions.map((reason) => {
            let score = res.data.data.find((score) => score?.key == reason.value);
            if (!score){
              return reason
            }
            
           
            let index = reason.text.lastIndexOf("]")
            if (index != -1){
              reason.text = reason.text.slice(index+1)
            }
            if (score?.key.startsWith("FEED")){
              prefix_text = "(피드) [클연 " +score.score + "점] "
            } else {
              prefix_text = "[클연 " +score.score + "점] "
            }

            reason.text = prefix_text + reason.text;
            return reason
          });
          
        })
        .catch((err) => {
          console.log("Reason Score",err);
          this.$toast.error(this.contentString.errorMsg[this.loginCountry], {
            position: "top",
            duration: 1200,
          });
        });
    },
    resetReportReason() {
      this.selectedReason = [this.contentString.choice[this.loginCountry]];
    },

    resetReportType() {
      this.selectedType = [this.contentString.choice[this.loginCountry]];
    },

    getReportCategoryTypes(){
      return [
        "CALL",
        "CHAT",
        "CONVERSATION",
        "FEED",
        "COMMENT",
        "SWIPE",
        "PROFILE",
      ]
    },
    getControlDomainTypes() {
      

      return [...this.controlDomainTypes]
    },

    checkDefaultReason() {
      for (let i = 0; i < this.reasonOptions.length; i++) {
        if (this.reportType == this.reasonOptions[i].value) {
          this.selectedReason = [this.reasonOptions[i].value];
        }
      }
      for (let i = 0; i < this.typeString.length; i++) {
        if (this.reportType == this.typeString[i].value) {
          this.selectedType = [this.typeString[i].value];
        }
      }
    },
    selectReportDomain(reportDomain) {
      this.reportCategory = reportDomain;
      this.updateReport();
    },
    selectControlDomainType(controlDomainType) {
      let filteredUserControls = [];
      if (controlDomainType === "ALL") {
        filteredUserControls = this.targetUser.user_controls;
      } else {
        filteredUserControls = this.targetUser.user_controls.filter(
          (control) => control.domain === controlDomainType
        );
      }

      filteredUserControls = filteredUserControls.map((control) => {
        return {
          type: control.control_type,
          reason: this.getReasonText(control.reason),
          date: this.$moment(control.created_at).format("YY/MM/DD_HH:mm"),
          is_active: control.is_active,
          domain: control.domain,
        };
      });
      this.targetUserControlHistory = filteredUserControls;
      this.currentControlDomainType = controlDomainType;
    },
    refreshCountDown() {
      setTimeout(() => {
        this.countDown--;
        setTimeout(() => {
          this.countDown--;
          setTimeout(() => {
            this.countDown--;
            setTimeout(() => {
              this.countDown--;
            }, 1000);
          }, 1000);
        }, 1000);
      }, 1000);
    },
  },
};
</script>

<style scoped>
.resultBtn {
  margin-left: -8%;
  margin-right: 3%;
  margin-top: 5px;
  word-break: keep-all;
}

.detailBtn {
  padding-top: 0px;
  padding-bottom: 0px;
  word-break: break-all;
  font-size: small;
}

.acceptBtn {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  width: 160px;
  font-size: small;
}

.userInfo {
  border-bottom: groove;
  border-width: 1px;
  word-break: break-all;
  font-size: small;
}

.selectAction {
  margin-bottom: 0px;
}

.table {
  font-size: small;
  word-break: break-all;
  text-align-last: center;
}

.moreBtn {
  display: flex;
  justify-content: center;
}



</style>
